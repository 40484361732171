import React from 'react';
import PropTypes from 'prop-types';
import { PluginStore } from 'graylog-web-plugin/plugin';
import moment from 'moment';
import {} from 'moment-duration-format';
import { capitalize, toLower } from 'lodash';

import Timestamp from 'components/common/Timestamp';
import DateTime from 'logic/datetimes/DateTime';
import StoreProvider from 'injection/StoreProvider';
import { DEFAULT_TIMERANGE } from 'views/Constants';

import { MESSAGE_LIST_WIDGET_LIMIT } from '../Constants';

const StreamsStore = StoreProvider.getStore('Streams');

export const FallbackWidgetDescription = ({ error }) => {
  return <span>Error generating widget description:<br /><code>{error.toString()}</code></span>;
};

FallbackWidgetDescription.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
};

class WidgetDescription extends React.Component {
  static propTypes = {
    widget: PropTypes.object.isRequired,
    calculatedAt: PropTypes.string,
  };

  static defaultProps = {
    calculatedAt: DateTime.now().toISOString(),
  };

  constructor(props) {
    super(props);
    this.state = this._cleanState();
  }

  componentDidMount() {
    this._loadStreams();
  }

  componentWillReceiveProps(nextProps) {
    const { widget } = this.props;

    if (nextProps.widget.dashboard_widget_id !== widget.dashboard_widget_id) {
      this.setState(this._cleanState());
    }
  }

  componentDidUpdate(prevProps) {
    const { widget } = this.props;

    if (prevProps.widget.dashboard_widget_id !== widget.dashboard_widget_id) {
      this._loadStream();
    }
  }

  _cleanState = () => {
    return {
      streams: [],
    };
  };

  _loadStreams = () => {
    const { widget } = this.props;
    const { streams: streamIds } = widget;

    if (streamIds && streamIds.length > 0) {
      StreamsStore.listStreams().then((streams) => this.setState({ streams: streams }));
    }
  };

  _timeRangeDescription = (widgetTimeRange) => {
    const timeRange = widgetTimeRange ?? DEFAULT_TIMERANGE;

    switch (timeRange.type) {
      case 'relative':
        // eslint-disable-next-line no-case-declarations
        const formattedTimeRange = moment.duration(timeRange.range, 'seconds')
          .format('d [days] h [hours] m [minutes] s [seconds]', { trim: 'all', useToLocaleString: false });

        return <span>in the last {formattedTimeRange}</span>;
      case 'absolute':
        return <span>from <Timestamp dateTime={timeRange.from} /> to <Timestamp dateTime={timeRange.to} /></span>;
      case 'keyword':
        return <span>in the {toLower(timeRange.keyword)}</span>;
      default:
        // Do nothing
    }
  };

  _streamsDescription = (streamIds) => {
    const { streams } = this.state;

    if (!streamIds || streamIds.length === 0) {
      return 'all messages';
    }

    const usedStreams = streams
      .filter((s) => streamIds.includes(s.id))
      .map((s) => <em>{s.title}</em>)
      .reduce((acc, current, idx, titles) => {
        if (titles.length === 1) {
          return <>{current}</>;
        }

        if (idx === titles.length - 1) {
          return <>{acc}and {current}</>;
        }

        return <>{acc}{current}, </>;
      }, React.Fragment);

    return <>messages in {streamIds.length === 1 ? 'stream' : 'streams'} {usedStreams}</>;
  };

  _calculatedAtDescription = (calculatedAt) => {
    return <Timestamp dateTime={calculatedAt} format={DateTime.Formats.COMPLETE} />;
  };

  _visualizationDescription = (config) => {
    const visualization = PluginStore.exports('visualizationTypes').filter((viz) => viz.type === config.visualization)[0];

    if (!visualization) {
      throw new Error(`Unable to find visualization component for type: ${config.visualization}`);
    }

    return visualization.displayName;
  };

  _widgetDescription = (widgetPlugin, widget, config) => {
    switch (widgetPlugin.type) {
      case 'AGGREGATION':
        return (
          <>
            {capitalize(this._visualizationDescription(config))}
            {' '}{toLower(widgetPlugin.titleGenerator(widget))}
          </>
        );
      case 'MESSAGES':
        return `Message list (limited to ${MESSAGE_LIST_WIDGET_LIMIT} messages)`;
      default:
        return 'Unknown widget';
    }
  }

  _queryDescription = (query) => {
    return <em>{query.query_string || '*'}</em>;
  };

  _generateDescription = (widgetPlugin, widget, calculatedAt) => {
    const { config, streams: streamIds, timerange, query } = widget;

    return (
      <>
        {this._widgetDescription(widgetPlugin, widget, config)}
        {' '}for
        {' '}{this._streamsDescription(streamIds)}
        {' '}{this._timeRangeDescription(timerange)}.
        {' '}The visualization represents data for query
        {' '}{this._queryDescription(query || {})}
        {' '}and was calculated at {this._calculatedAtDescription(calculatedAt)}.
      </>
    );
  }

  render() {
    const { widget, calculatedAt } = this.props;
    const widgetPlugin = PluginStore.exports('enterpriseWidgets').find((w) => w.type.toUpperCase() === widget.type.toUpperCase());

    if (!widgetPlugin) {
      return 'No description available.';
    }

    return this._generateDescription(widgetPlugin, widget, calculatedAt);
  }
}

export default WidgetDescription;
