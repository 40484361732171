import React from 'react';
import createReactClass from 'create-react-class';

import ReportLicenseStatusContainer from './validations/ReportLicenseStatusContainer';

const ReportValidations = createReactClass({
  render() {
    const licenseValidation = <ReportLicenseStatusContainer rowClass="content row-sm" />;

    return (
      <div>
        {licenseValidation}
      </div>
    );
  },
});

export default ReportValidations;
