// @flow strict
import * as React from 'react';
import { useEffect, useState } from 'react';

import withParams from 'routing/withParams';
import { PageHeader, DocumentTitle } from 'components/common';
import UserOverviewLinks from 'components/users/navigation/UserOverviewLinks';
import DocumentationLink from 'components/support/DocumentationLink';
import DocsHelper from 'util/DocsHelper';

import TeamDetails from '../components/team-details/TeamDetails';
import TeamsDomain from '../domainActions/TeamsDomain';
import TeamActionLinks from '../components/navigation/TeamActionLinks';

type Props = {
  params: {
    teamId: string,
  },
};

const PageTitle = ({ name }: {name: ?string}) => (
  <>
    Team Details {name && (
    <>
      - <i>{name}</i>
    </>
  )}
  </>
);

const TeamDetailsPage = ({ params }: Props) => {
  const teamId = params?.teamId;
  const [team, setTeam] = useState();
  const name = team?.name;

  useEffect(() => {
    TeamsDomain.load(teamId).then(setTeam);
  }, [teamId]);

  return (
    <DocumentTitle title={`Team Details ${name ?? ''}`}>
      <PageHeader title={<PageTitle name={name} />}
                  subactions={<TeamActionLinks team={team} />}>
        <span>
          Overview of details like settings, assigned users and roles. This page also provides an overview of all entities which are being shared with this team.
        </span>

        <span>
          Learn more in the{' '}
          <DocumentationLink page={DocsHelper.PAGES.WELCOME}
                             text="documentation" />
        </span>

        <UserOverviewLinks />
      </PageHeader>

      <TeamDetails team={team} />
    </DocumentTitle>
  );
};

export default withParams(TeamDetailsPage);
