// @flow strict
import * as React from 'react';
import styled from 'styled-components';

import ConnectionErrors, { NotificationContainer, Title } from 'components/authentication/directoryServices/BackendWizard/ConnectionErrors';

import type { TriggerTeamSyncResult } from '../../actions/AuthenticationActions';

const Table = styled.table`
  width: 250px;
  margin-bottom: 0;
`;

type Props = {
  response: TriggerTeamSyncResult,
};

const TriggerTeamSyncResponse = ({ response }: Props) => {
  const { success, message, errors, createdTeams = [], deletedTeams = [], updatedTeams = [] } = response;

  if (errors && errors.length >= 1) {
    return <ConnectionErrors errors={errors} message={message} />;
  }

  return (
    <NotificationContainer bsStyle={success ? 'success' : 'danger'}>
      <Title>{message}</Title>
      <Table className="table">
        <tbody>
          <tr>
            <td className="limited">
              Created Teams
            </td>
            <td>
              {createdTeams.length}
            </td>
          </tr>
          <tr>
            <td className="limited">
              Updated Teams
            </td>
            <td>
              {updatedTeams.length}
            </td>
          </tr>
          <tr>
            <td className="limited">
              Deleted Teams
            </td>
            <td>
              {deletedTeams.length}
            </td>
          </tr>
        </tbody>
      </Table>
    </NotificationContainer>
  );
};

export default TriggerTeamSyncResponse;
