exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* Restore bootstrap heading margins because our stylesheet is :shit: */\n._2lZM-hmMmBj1J4mmK4_TvR h1, ._2lZM-hmMmBj1J4mmK4_TvR h2, ._2lZM-hmMmBj1J4mmK4_TvR h3 {\n    margin-top: 20px;\n    margin-bottom: 10px;\n}\n\n._2lZM-hmMmBj1J4mmK4_TvR h4, ._2lZM-hmMmBj1J4mmK4_TvR h5, ._2lZM-hmMmBj1J4mmK4_TvR h6 {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"bootstrapHeading": "_2lZM-hmMmBj1J4mmK4_TvR"
};