// @flow strict
import * as React from 'react';
import * as Immutable from 'immutable';
import { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';

const SelectionTypes = styled.div`
  display: flex;

  div + * {
    margin-left: 10px;
  }
`;

type Props = {
  disabled: boolean,
};

const SelectionTypeOptions = ({ disabled }: Props) => {
  const { values: { teamSelectionType, teamSelection }, setFieldValue, errors, setErrors } = useFormikContext();

  useEffect(() => {
    if (teamSelectionType === 'all' && teamSelection.size >= 1) {
      setFieldValue('teamSelection', Immutable.Set());
    }
  }, [teamSelectionType, teamSelection, setFieldValue]);

  const _onSelectionTypeChange = (event, onChange) => {
    const nextSelectionType = event.target.value;

    if (teamSelectionType !== 'all' && nextSelectionType === 'all' && teamSelection.size >= 1) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Do you really want to change the selection type to "All"? You can always change the selection type, but your selected groups will get lost.')) {
        onChange(event);
      }

      return;
    }

    if (teamSelectionType !== 'all' && nextSelectionType === 'all' && errors?.teamSelection) {
      setErrors({ ...errors, teamSelection: undefined });

      return;
    }

    onChange(event);
  };

  return (
    <Input id="teamSelectionType"
           label="Selection type"
           disabled={disabled}
           help="With include / exclude you can select which groups you want / do not want to synchronize."
           labelClassName="col-sm-3"
           wrapperClassName="col-sm-9">
      <Field name="teamSelectionType">
        {({ field: { name, onChange, onBlur, value } }) => (
          <SelectionTypes>
            <Input checked={value === 'all'}
                   formGroupClassName=""
                   id={name}
                   disabled={disabled}
                   label="All groups"
                   onBlur={onBlur}
                   onChange={(e) => _onSelectionTypeChange(e, onChange)}
                   type="radio"
                   value="all" />
            <Input checked={value === 'include'}
                   formGroupClassName=""
                   disabled={disabled}
                   id={name}
                   label="Include selected"
                   onBlur={onBlur}
                   onChange={(e) => _onSelectionTypeChange(e, onChange)}
                   type="radio"
                   value="include" />
            <Input checked={value === 'exclude'}
                   formGroupClassName=""
                   id={name}
                   disabled={disabled}
                   label="Exclude selected"
                   onBlur={onBlur}
                   onChange={(e) => _onSelectionTypeChange(e, onChange)}
                   type="radio"
                   value="exclude" />
          </SelectionTypes>
        )}
      </Field>
    </Input>
  );
};

export default SelectionTypeOptions;
