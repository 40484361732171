import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import moment from 'moment';

import { Button, ControlLabel, FormControl, FormGroup, Radio } from 'components/graylog';
import { extractDurationAndUnit } from 'components/common/TimeUnitInput';
import { Pluralize, Select, TimeUnitInput } from 'components/common';
import FormsUtils from 'util/FormsUtils';

import commonStyles from './commonStyles.css';

const formatEventDefinitions = (eventDefinitions) => {
  return eventDefinitions.map((definition) => ({ label: definition.title, value: definition.id }));
};

const TIME_UNITS = ['HOURS', 'MINUTES', 'SECONDS'];

class CorrelationRule extends React.Component {
  static propTypes = {
    number: PropTypes.number.isRequired,
    correlationRule: PropTypes.object.isRequired,
    eventDefinitions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const notForPeriod = lodash.defaultTo(props.correlationRule.not_for_period, 'PT1S');
    const { duration, unit } = extractDurationAndUnit(notForPeriod, TIME_UNITS);

    this.state = {
      occurrence: lodash.defaultTo(props.correlationRule.occurrence, 1),
      notForPeriod: notForPeriod,
      notForPeriodDuration: duration,
      notForPeriodUnit: unit,
    };
  }

  propagateChange = (ruleUpdate = {}) => {
    const { correlationRule, onChange } = this.props;
    const nextRule = { ...correlationRule, ...ruleUpdate };
    onChange(correlationRule.id, nextRule);
  };

  handleEventDefinitionChange = (nextEventDefinition) => {
    this.propagateChange({ event_creator_equals: nextEventDefinition });
  };

  handleOccurrenceChange = (event) => {
    const value = FormsUtils.getValueFromInput(event.target);
    this.propagateChange({ occurrence: value });
    this.setState({ occurrence: value });
  };

  handleStateLogicChange = (event) => {
    const { occurrence, notForPeriod } = this.state;
    const value = FormsUtils.getValueFromInput(event.target);
    let ruleUpdate;

    switch (value) {
      case 'not_and':
        ruleUpdate = { state_logic: value, occurrence: undefined, not_for_period: undefined };
        break;
      case 'not_for':
        ruleUpdate = { state_logic: value, occurrence: undefined, not_for_period: notForPeriod };
        break;
      default:
        ruleUpdate = { state_logic: undefined, occurrence: occurrence, not_for_period: undefined };
    }

    this.propagateChange(ruleUpdate);
  };

  handleNotForPeriodChange = (nextValue, nextUnit) => {
    const duration = moment.duration(lodash.max([nextValue, 1]), nextUnit).toISOString();
    this.propagateChange({ not_for_period: duration });

    this.setState({
      notForPeriodDuration: nextValue,
      notForPeriodUnit: nextUnit,
      notForPeriod: duration,
    });
  };

  handleRemove = () => {
    const { correlationRule, onRemove } = this.props;
    onRemove(correlationRule.id);
  };

  renderFirstRuleOptions = (correlationRule) => {
    const { occurrence } = this.state;

    return (
      <div className={`form-inline ${commonStyles.inlineFormGroup}`}>
        <FormGroup>
          <FormControl.Static>Should occur at least</FormControl.Static>
        </FormGroup>
        <FormControl id={`${correlationRule.id}-occurrence`}
                     componentClass="input"
                     type="number"
                     name="occurrence"
                     value={occurrence}
                     onChange={this.handleOccurrenceChange} />
        <FormGroup>
          <FormControl.Static>
            <Pluralize singular="time" plural="times" value={occurrence} />.
          </FormControl.Static>
        </FormGroup>
      </div>
    );
  };

  renderRegularRuleOptions = (correlationRule) => {
    const { occurrence, notForPeriodDuration, notForPeriodUnit } = this.state;

    return (
      <FormGroup>
        <ControlLabel>Should</ControlLabel>
        <Radio id={`${correlationRule.id}-regular-rule-type`}
               name={`${correlationRule.id}-rule-type`}
               value="regular"
               className={commonStyles.complexRadio}
               checked={correlationRule.state_logic === undefined || correlationRule.state_logic === null}
               onChange={this.handleStateLogicChange}>
          <div className={`form-inline ${commonStyles.inlineFormGroup}`}>
            <FormGroup>
              <FormControl.Static>Occur at least</FormControl.Static>
            </FormGroup>
            <FormGroup>
              <FormControl id={`${correlationRule.id}-occurrence`}
                           componentClass="input"
                           type="number"
                           name="occurrence"
                           value={occurrence}
                           disabled={correlationRule.state_logic !== undefined && correlationRule.state_logic !== null}
                           onChange={this.handleOccurrenceChange} />
            </FormGroup>
            <FormGroup>
              <FormControl.Static>
                <Pluralize singular="time" plural="times" value={occurrence} />.
              </FormControl.Static>
            </FormGroup>
          </div>
        </Radio>

        <Radio id={`${correlationRule.id}-not-and-rule-type`}
               name={`${correlationRule.id}-rule-type`}
               value="not_and"
               checked={correlationRule.state_logic === 'not_and'}
               onChange={this.handleStateLogicChange}>
          Not occur, and it is followed by another Event
        </Radio>

        <Radio id={`${correlationRule.id}-not-for-rule-type`}
               name={`${correlationRule.id}-rule-type`}
               value="not_for"
               className={commonStyles.complexRadio}
               checked={correlationRule.state_logic === 'not_for'}
               onChange={this.handleStateLogicChange}>
          <div className={`form-inline ${commonStyles.inlineFormGroup}`}>
            <FormGroup>
              <FormControl.Static>Not occur in the next</FormControl.Static>
            </FormGroup>
            <FormGroup>
              <TimeUnitInput update={this.handleNotForPeriodChange}
                             value={notForPeriodDuration}
                             unit={notForPeriodUnit}
                             units={TIME_UNITS}
                             enabled={correlationRule.state_logic === 'not_for'}
                             clearable
                             hideCheckbox />
            </FormGroup>
          </div>
        </Radio>
      </FormGroup>
    );
  };

  renderRuleOptions = (number, correlationRule) => {
    if (number === 1) {
      return this.renderFirstRuleOptions(correlationRule);
    }

    return this.renderRegularRuleOptions(correlationRule);
  };

  render() {
    const { number, correlationRule, eventDefinitions } = this.props;

    return (
      <fieldset className={commonStyles.rule}>
        <legend>
          Event #{number}
          {number !== 1 && (
            <span className="pull-right">
              <Button bsSize="xsmall" bsStyle="primary" onClick={this.handleRemove}>Remove</Button>
            </span>
          )}
        </legend>

        <div className={`form-inline ${commonStyles.inlineFormGroup}`}>
          <FormGroup>
            <FormControl.Static>Event from</FormControl.Static>
          </FormGroup>
          <div className={commonStyles.inlineSelect}>
            <Select id={`${correlationRule.id}-event-definition`}
                    matchProp="label"
                    onChange={this.handleEventDefinitionChange}
                    options={formatEventDefinitions(eventDefinitions)}
                    value={correlationRule.event_creator_equals}
                    required />
          </div>
        </div>

        {this.renderRuleOptions(number, correlationRule)}
      </fieldset>
    );
  }
}

export default CorrelationRule;
