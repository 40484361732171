// @flow strict
import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';

import withParams from 'routing/withParams';
import { DocumentTitle, PageHeader } from 'components/common';
import UserOverviewLinks from 'components/users/navigation/UserOverviewLinks';
import DocumentationLink from 'components/support/DocumentationLink';
import DocsHelper from 'util/DocsHelper';

import { TeamsActions } from '../stores/TeamsStore';
import TeamsDomain from '../domainActions/TeamsDomain';
import TeamEdit from '../components/team-edit/TeamEdit';
import TeamActionLinks from '../components/navigation/TeamActionLinks';

type Props = {
  params: {
    teamId: string,
  },
};

const PageTitle = ({ name }: {name: ?string}) => (
  <>
    Team Edit {name && (
    <>
      - <i>{name}</i>
    </>
  )}
  </>
);

const TeamEditPage = ({ params }: Props) => {
  const teamId = params?.teamId;
  const [team, setTeam] = useState();
  const name = team?.name;
  const fetchTeam = useCallback(() => TeamsDomain.load(teamId).then(setTeam), [teamId]);

  useEffect(() => { fetchTeam(); }, [teamId, fetchTeam]);
  useEffect(() => TeamsActions.update.completed.listen(fetchTeam), [teamId, fetchTeam]);
  useEffect(() => TeamsActions.addMembers.completed.listen(fetchTeam), [teamId, fetchTeam]);
  useEffect(() => TeamsActions.removeMember.completed.listen(fetchTeam), [teamId, fetchTeam]);

  return (
    <DocumentTitle title={`Team Edit ${name ?? ''}`}>
      <PageHeader title={<PageTitle name={name} />}
                  subactions={<TeamActionLinks team={team} />}>
        <span>
          Edit settings and assign users and roles for this team.
        </span>

        <span>
          Learn more in the{' '}
          <DocumentationLink page={DocsHelper.PAGES.WELCOME}
                             text="documentation" />
        </span>

        <UserOverviewLinks />
      </PageHeader>

      <TeamEdit team={team} />
    </DocumentTitle>
  );
};

export default withParams(TeamEditPage);
