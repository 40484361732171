import Reflux from 'reflux';

const ArchiveActions = Reflux.createActions({
  config: { asyncResult: true },
  saveConfig: { asyncResult: true },
  searchPaginated: { asyncResult: true },
  createArchive: { asyncResult: true },
  restoreArchive: { asyncResult: true },
  deleteArchive: { asyncResult: true },
  rebuildCatalog: { asyncResult: true },
  availability: { asyncResult: true },
  listBackends: { asyncResult: true },
  deleteBackend: { asyncResult: true },
  getBackend: { asyncResult: true },
  saveBackend: { asyncResult: true },
  checkOutputTemplate: { asyncResult: true },
  exportFilenames: { asyncResult: true },
});

export default ArchiveActions;
