// @flow strict
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import type { StyledComponent } from 'styled-components';

import { Badge } from 'components/graylog';
import CustomizationContext from 'contexts/CustomizationContext';
import CombinedProvider from 'injection/CombinedProvider';
import type { ThemeInterface } from 'theme';

const { CustomizationsActions } = CombinedProvider.get('Customizations');

const CUSTOMIZATION_HEADER_BADGE = 'org.graylog.plugins.customization.HeaderBadge';

const StyledBadge: StyledComponent<{color: string}, ThemeInterface, typeof Badge> = styled(Badge)(({ color, theme }) => css`
  background-color: ${color};
  color: ${theme.utils.readableColor(color)};
`);

type Props = {
  smallScreen: boolean,
};

const HeaderBadge = ({ smallScreen = false }: Props) => {
  const smallScreenClass = smallScreen ? 'small-scrn-badge' : '';
  const badgeConfig = useContext(CustomizationContext)[CUSTOMIZATION_HEADER_BADGE];

  useEffect(() => {
    CustomizationsActions.get(CUSTOMIZATION_HEADER_BADGE);
  }, []);

  // eslint-disable-next-line camelcase
  if (badgeConfig?.badge_enable) {
    return (
      <StyledBadge color={badgeConfig.badge_color}
                   className={`${smallScreenClass} dev-badge`}>
        {badgeConfig.badge_text}
      </StyledBadge>
    );
  }

  return null;
};

HeaderBadge.propTypes = {
  smallScreen: PropTypes.bool,
};

HeaderBadge.defaultProps = {
  smallScreen: false,
};

export default HeaderBadge;
