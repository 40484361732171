// @flow strict
import * as React from 'react';
import PropTypes from 'prop-types';

// $FlowFixMe: imports from core need to be fixed in flow
import { ControlLabel, FormGroup, HelpBlock } from 'components/graylog';
// $FlowFixMe: imports from core need to be fixed in flow
import Select from 'components/common/Select';
// $FlowFixMe: imports from core need to be fixed in flow
import Spinner from 'components/common/Spinner';
// $FlowFixMe: imports from core need to be fixed in flow
import { BootstrapModalForm, Input } from 'components/bootstrap';
import type { ViewSummaries, ViewSummary } from 'views/stores/ViewManagementStore';
import { ViewManagementActions } from 'views/stores/ViewManagementStore';
import Parameter from 'views/logic/parameters/Parameter';

import styles from './SelectViewWithParameter.css';

type State = {
  viewSummaries: ?ViewSummaries,
  selectedView: ?ViewSummary,
  selectedParameter: ?string,
};

type Props = {
  onSubmit: (?string, ?string) => void,
};

class SelectViewWithParameter extends React.Component<Props, State> {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    viewSummaries: undefined,
    selectedParameter: undefined,
    selectedView: undefined,
  };

  componentDidMount() {
    ViewManagementActions.forValue().then((viewSummaries: ViewSummaries) => this.setState({ viewSummaries }));
  }

  // eslint-disable-next-line react/destructuring-assignment
  _onSubmit = () => this.props.onSubmit(this.state.selectedView && this.state.selectedView.id, this.state.selectedParameter);

  _onChange = (selectedView: string) => {
    const { viewSummaries } = this.state;

    if (selectedView && viewSummaries) {
      const view: ?ViewSummary = viewSummaries.find((v) => v.id === selectedView);

      if (view) {
        const parameter = view.parameters[0];

        this.setState({
          selectedView: view,
          selectedParameter: parameter.name,
        });

        return;
      }
    }

    this.setState({
      selectedParameter: undefined,
      selectedView: undefined,
    });
  };

  _onParameterChange = (event: SyntheticInputEvent<HTMLInputElement>) => this.setState({ selectedParameter: event.target.value });

  // eslint-disable-next-line react/no-unused-prop-types
  _renderOption = ({ label, help }: { label: string, help: string }) => <><strong>{label}</strong>{help && ` - ${help}`}</>;

  render() {
    const { viewSummaries, selectedView, selectedParameter } = this.state;

    let content = <Spinner />;

    if (viewSummaries) {
      const viewOptions = viewSummaries
        .sort((v1, v2) => v1.title.localeCompare(v2.title))
        .map((viewSummary: ViewSummary) => ({ label: viewSummary.title, value: viewSummary.id, help: viewSummary.summary }));

      let parameterSelection = null;

      if (selectedView) {
        const { parameters } = selectedView;

        parameterSelection = (
          <>
            <FormGroup className={styles.indentingParameters}>
              <ControlLabel>Select Parameter:</ControlLabel>
              {parameters.map((parameter: Parameter) => (
                <Input type="radio"
                       key={`radio-parameter-${parameter.name}`}
                       id={parameter.name}
                       name="parameter"
                       value={parameter.name}
                       label={parameter.title}
                       onChange={this._onParameterChange}
                       help={parameter.description}
                       checked={selectedParameter === parameter.name} />
              ))}
            </FormGroup>
          </>
        );
      }

      const selectedViewId = selectedView ? selectedView.id : undefined;

      content = (
        <>
          <FormGroup>
            <Select placeholder="Select from views with parameters"
                    options={viewOptions}
                    optionRenderer={this._renderOption}
                    onChange={this._onChange}
                    clearable
                    value={selectedViewId} />
            <HelpBlock>
              The value will be passed to the selected view and used as a value for a parameter.
              Only views containing at least one parameter are listed here.
              In the next step you can select the parameter which will be used, if more than one exists.
            </HelpBlock>
          </FormGroup>

          {parameterSelection}

        </>
      );
    }

    return (
      <BootstrapModalForm title="Select view"
                          submitButtonDisabled={!selectedParameter}
                          show
                          onSubmitForm={this._onSubmit}>
        {content}
      </BootstrapModalForm>
    );
  }
}

export default SelectViewWithParameter;
