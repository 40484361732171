import PropTypes from 'prop-types';
import React from 'react';
import lodash from 'lodash';
import moment from 'moment';

import { Select } from 'components/common';

class TimePicker extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: undefined,
    disabled: false,
    required: false,
  };

  render() {
    const { id, value, disabled, required, onChange } = this.props;

    const time = moment().minutes(0);
    const hours = lodash.range(0, 24).map((hour) => {
      const formattedTime = time.hours(hour).format('HH:mm');

      return { value: formattedTime, label: formattedTime };
    });

    return (
      <Select id={id}
              options={hours}
              value={value}
              placeholder="Select time of the day"
              disabled={disabled}
              onChange={onChange}
              required={required} />
    );
  }
}

export default TimePicker;
