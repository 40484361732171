// @flow strict
import * as React from 'react';
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Immutable from 'immutable';

import { DEFAULT_PAGINATION, type DescriptiveItem } from 'components/common/PaginatedItemOverview';
import { PaginatedItemOverview } from 'components/common';
import type { Pagination } from 'stores/PaginationTypes';

import TeamsSelector from './TeamsSelector';
import TeamQuickCreate from './TeamQuickCreate';

import type { PaginatedTeams } from '../../actions/TeamsActions';
import TeamsQueryHelp from '../teams-overview/TeamsQueryHelper';
import TeamsDomain from '../../domainActions/TeamsDomain';
import LicenseCheck from '../../../license/LicenseCheck';
import Team from '../../logic/Team';

type Props = {
  readOnly: boolean,
  loadAssignedTeams: (pagination: Pagination) => Promise<PaginatedTeams>,
  unassignTeam: (teamId: $PropertyType<Team, 'id'>) => Promise<Team>,
  assignTeams: (teamIds: Immutable.Set<$PropertyType<Team, 'id'>>) => Promise<void>,
  buildTeamWithEntity: (team: Team) => Team,
  getUnassignedTeams: (teams: Immutable.List<Team>) => Immutable.List<Team>,
};

const Container = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const TeamsAssignment = ({ readOnly = false, loadAssignedTeams, buildTeamWithEntity, getUnassignedTeams, unassignTeam, assignTeams }: Props) => {
  const [paginatedTeams, setPaginatedTeams] = useState<?PaginatedTeams>();
  const [showCreationForm, setShowCreationForm] = useState(false);

  const _onLoad = useCallback((pagination = DEFAULT_PAGINATION) => loadAssignedTeams(pagination), [loadAssignedTeams]);

  const _unassignTeam = (team: DescriptiveItem) => {
    unassignTeam(team.id).then(() => _onLoad().then(setPaginatedTeams));
  };

  const _onAssignTeams = (teamIds: Immutable.Set<$PropertyType<Team, 'id'>>) => {
    return assignTeams(teamIds).then(() => {
      _onLoad().then(setPaginatedTeams);
    });
  };

  const _onCreateTeam = (newTeam: Team) => {
    return TeamsDomain.create(buildTeamWithEntity(newTeam)).then((createdTeam) => {
      _onLoad().then(setPaginatedTeams);

      return createdTeam;
    });
  };

  return (
    <LicenseCheck featureName="teams">
      {({ licenseIsValid }) => {
        const isReadOnly = readOnly || !licenseIsValid;

        return (
          <>
            {!isReadOnly && (
              <>
                <h3>Assign Teams</h3>
                <Container>
                  <TeamsSelector onSubmit={_onAssignTeams}
                                 getUnassignedTeams={getUnassignedTeams}
                                 showCreationForm={() => setShowCreationForm(true)} />
                </Container>
              </>
            )}
            {!isReadOnly && showCreationForm ? (
              <>
                <h3>Quick Create Team</h3>
                <Container>
                  <TeamQuickCreate onClose={() => setShowCreationForm(false)} onSubmit={_onCreateTeam} />
                </Container>
              </>
            ) : (
              <>
                <h3>Selected Teams</h3>
                <Container>
                  <PaginatedItemOverview noDataText="No selected teams have been found"
                                         overrideList={paginatedTeams}
                                         onLoad={_onLoad}
                                         queryHelper={<TeamsQueryHelp />}
                                         onDeleteItem={!isReadOnly ? _unassignTeam : undefined} />
                </Container>
              </>
            )}
          </>
        );
      }}
    </LicenseCheck>
  );
};

TeamsAssignment.propTypes = {
  readOnly: PropTypes.bool,
};

TeamsAssignment.defaultProps = {
  readOnly: false,
};

export default TeamsAssignment;
