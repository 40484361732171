// @flow strict
import * as React from 'react';
import PropTypes from 'prop-types';
import * as Immutable from 'immutable';

import ParameterDeclarationForm from 'enterprise/parameters/components/ParameterDeclarationForm';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import { newParameterState, SearchParameterActions } from 'enterprise/parameters/stores/SearchParameterStore';

type Props = {
  onClose: () => void,
};

class CreateParameterDialog extends React.Component<Props> {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  onSave = (newParameters: ParameterMap) => {
    const { onClose } = this.props;
    SearchParameterActions.declare(newParameters).then(onClose);
  };

  render() {
    const newParameters = Immutable.fromJS({
      newParameter: newParameterState('newParameter'),
    });
    const { onClose } = this.props;

    return <ParameterDeclarationForm allowEditingName show parameters={newParameters} onSave={this.onSave} onClose={onClose} />;
  }
}

export default CreateParameterDialog;
