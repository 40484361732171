import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'components/graylog/router';

import { Button, ButtonToolbar } from 'components/graylog';
import Routes from 'routing/Routes';

const ReportContentsToolbar = ({ action, formElementId, isLoading, onCancel }) => {
  let submitText;

  if (action === 'create') {
    submitText = isLoading ? 'Creating report...' : 'Create report';
  } else {
    submitText = isLoading ? 'Updating report...' : 'Update report';
  }

  return (
    <ButtonToolbar>
      <Button type="submit" form={formElementId} bsStyle="success" disabled={isLoading}>
        {submitText}
      </Button>
      <LinkContainer to={Routes.pluginRoute('REPORTS')} onClick={onCancel}>
        <Button className="cancel">Cancel</Button>
      </LinkContainer>
    </ButtonToolbar>
  );
};

ReportContentsToolbar.propTypes = {
  action: PropTypes.oneOf(['create', 'edit']).isRequired,
  formElementId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
};

ReportContentsToolbar.defaultProps = {
  isLoading: false,
};

export default ReportContentsToolbar;
