exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._31OtyBLi3WsMNhPfXwbuqW {\n    display: inline-block;\n    width: 100%;\n}\n\n._3j5so7g1LUbdWCfKH-xX4j {\n    float: left;\n    margin-right: 10px;\n}", ""]);

// exports
exports.locals = {
	"listGroupItem": "_31OtyBLi3WsMNhPfXwbuqW",
	"itemHandle": "_3j5so7g1LUbdWCfKH-xX4j"
};