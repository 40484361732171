// @flow strict
import * as React from 'react';
import { useState, useEffect } from 'react';
import * as Immutable from 'immutable';

import { STEP_KEY as GROUP_SYNC_KEY } from 'components/authentication/directoryServices/BackendWizard/GroupSyncStep';
import EditLinkButton from 'components/authentication/directoryServices/BackendConfigDetails/EditLinkButton';
import { Spinner } from 'components/common';
import { ButtonToolbar } from 'components/graylog';
import SectionComponent from 'components/common/Section/SectionComponent';
import Role from 'logic/roles/Role';
import type { DirectoryServiceBackend } from 'logic/authentication/directoryServices/types';

import GroupSyncDetails from './GroupSyncDetails';
import TriggerTeamSyncResponse from './TriggerTeamSyncResponse';

import TriggerTeamSyncButton from '../TriggerTeamSyncButton';
import type { TriggerTeamSyncResult } from '../../actions/AuthenticationActions';
import { GroupSyncActions } from '../../stores/directoryServices/GroupSyncStore';
import GroupSyncBackend from '../../logic/directoryServices/GroupSyncBackend';

type Props = {
  authenticationBackend: DirectoryServiceBackend,
  excludedFields?: {[ inputName: string ]: boolean },
  roles: Immutable.List<Role>,
};

const GroupSyncSection = ({ authenticationBackend: { id: backendId, title: backendTitle }, roles, excludedFields }: Props) => {
  const [groupSyncBackend, setGroupSyncBackend] = useState<?GroupSyncBackend>();
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [triggerSyncResponse, setTriggerSyncResponse] = useState<?TriggerTeamSyncResult>();

  useEffect(() => {
    GroupSyncActions.load(backendId).then((result) => {
      if (result && result.backend) {
        setGroupSyncBackend(result.backend);
      }

      setFinishedLoading(true);
    }).catch(() => {
      setFinishedLoading(true);
      setGroupSyncBackend();
    });
  }, [backendId]);

  if (!finishedLoading) {
    return <Spinner />;
  }

  return (
    <SectionComponent title="Group Synchronization"
                      headerActions={(
                        <ButtonToolbar>
                          {groupSyncBackend && (
                            <TriggerTeamSyncButton backendId={backendId}
                                                   backendTitle={backendTitle}
                                                   setResponse={setTriggerSyncResponse} />
                          )}
                          <EditLinkButton authenticationBackendId={backendId}
                                          stepKey={GROUP_SYNC_KEY} />
                        </ButtonToolbar>
                      )}>
      <GroupSyncDetails groupSyncBackend={groupSyncBackend} roles={roles} excludedFields={excludedFields} />
      {triggerSyncResponse && <TriggerTeamSyncResponse response={triggerSyncResponse} />}
    </SectionComponent>
  );
};

GroupSyncSection.defaultProps = {
  excludedFields: undefined,
};

export default GroupSyncSection;
