import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Reflux from 'reflux';

import { EntityList, Spinner } from 'components/common';
import { naturalSortIgnoreCase } from 'util/SortUtils';

import Report from './Report';

import ReportsActions from '../ReportsActions';
import ReportsStore from '../ReportsStore';

const ReportList = createReactClass({
  displayName: 'ReportList',
  propTypes: {
    licenseStatus: PropTypes.object.isRequired,
  },
  mixins: [Reflux.connect(ReportsStore)],

  componentDidMount() {
    ReportsActions.list();
  },

  _formatReport(report) {
    return <Report key={report.id} report={report} licenseStatus={this.props.licenseStatus} />;
  },

  _isLoading() {
    return !this.state.reports;
  },

  render() {
    if (this._isLoading()) {
      return <div><Spinner /></div>;
    }

    const { reports } = this.state;
    const sortedReports = reports.sort((r1, r2) => naturalSortIgnoreCase(r1.title, r2.title));

    return (
      <div>
        <EntityList noItemsText="There are no reports to display, click on 'Create report' to get started."
                    items={sortedReports.map((report) => this._formatReport(report))} />
      </div>
    );
  },
});

export default ReportList;
