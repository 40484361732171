exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Mzm86UX3sM7ClgjZWIcfi {\n    text-align: center;\n    width: 25px;\n}\n\n.Mzm86UX3sM7ClgjZWIcfi > i {\n    font-size: 1.333em;\n    line-height: 0.75em;\n}\n\n._2raCUetExLpQP8l5iVyxL5 {\n    width: 300px;\n}", ""]);

// exports
exports.locals = {
	"status": "Mzm86UX3sM7ClgjZWIcfi",
	"date": "_2raCUetExLpQP8l5iVyxL5"
};