// @flow strict
import * as React from 'react';

import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'components/graylog';
import Parameter from 'views/logic/parameters/Parameter';

type Props = {
  parameter: Parameter,
  value: any,
  onChange: (any) => void,
};

const ParameterInput = ({ parameter, value, onChange }: Props) => {
  const { name, title, description, optional, defaultValue } = parameter;

  return (
    <FormGroup key={`parameter-${name}`} controlId={`parameter-${name}`}>
      <Col componentClass={ControlLabel} sm={3}>
        {title}
      </Col>
      <Col sm={9}>
        <FormControl type="text"
                     placeholder={defaultValue || 'Please enter a parameter value...'}
                     required={!optional && !defaultValue}
                     name={name}
                     value={value || ''}
                     onChange={({ target: { value: newValue } }) => onChange(newValue)} />
        <HelpBlock>{description}</HelpBlock>
      </Col>
    </FormGroup>
  );
};

ParameterInput.propTypes = {};

export default ParameterInput;
