import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import styled, { css } from 'styled-components';

import { Alert, Col, Row } from 'components/graylog';
import { Icon } from 'components/common';

import PaperSheet from '../common/PaperSheet';
import ReportPreview from '../common/ReportPreview';
import ReportsActions from '../ReportsActions';

const StyledCoverPageRow = styled(Row)`
  padding: 20px 0 40px 0;
  white-space: pre-wrap;
  > h2 {
   margin-bottom: 10px;
  }
`;

const StyledHr = styled.hr(({ theme }) => css`
  border: none;
  border-top: 2px solid ${theme.colors.gray['100']};
  color: ${theme.colors.global.textDefault};
  height: 5px;
  overflow: visible;
  text-align: center;

  &:after {
    background: ${theme.colors.global.contentBackground};
    content: 'end of cover page';
    font-family: monospace;
    padding: 0 4px;
    position: relative;
    top: -0.9em;
  } 
`);

const StyledAlert = styled(Alert)(({ theme }) => css`
  font-size: ${theme.fonts.size.root};
  
  > strong {
    font-weight: 600;
  } 
`);

const StyledP = styled.p`
  white-space: pre-wrap;
`;

const StyledLogo = styled.div`
  padding: 10px;
  margin-top: 20px;
  text-align: center;
  max-width: 150px;
  max-height: 150px;
`;

class ReportLayout extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    reportLogo: PropTypes.string,
  };

  static defaultProps = {
    reportLogo: undefined,
  };

  _onPositionsChange = (positions) => {
    const { report: { id, positions: { reportPositions } } } = this.props;
    const updatedPositions = positions.map((position) => {
      return {
        dashboard_widget_id: position.id,
        row: position.row,
        col: position.col,
      };
    });

    if (!lodash.isEqual(updatedPositions, reportPositions)) {
      ReportsActions.updatePositions(id, updatedPositions);
    }
  };

  _renderReportContents = (report) => {
    if (report.widgets.length === 0) {
      return (
        <StyledAlert bsStyle="info">
          <Icon name="info-circle" /> <strong>No widgets have been added to this report.</strong>{' '}
          Modify the report&#039;s content and select which widgets you want to include in the report.
        </StyledAlert>
      );
    }

    return <ReportPreview report={report} onPositionsChange={this._onPositionsChange} />;
  };

  render() {
    const { report, reportLogo } = this.props;

    return (
      <div>
        <h3>Layout</h3>
        <p>
          This is a preview of the report. Drag and drop widgets to sort the report contents, your changes will be
          updated automatically.
        </p>
        <PaperSheet>
          <StyledCoverPageRow>
            <Col md={12}>
              <h2 className="text-center">{report.title}</h2>
              <h4 className="text-center">{report.subtitle}</h4>
              {reportLogo && <StyledLogo><img src={reportLogo} alt="report-logo" /></StyledLogo>}
            </Col>
          </StyledCoverPageRow>
          <StyledHr />
          <Row>
            <Col md={12}>
              <StyledP>{report.description}</StyledP>
              {this._renderReportContents(report)}
            </Col>
          </Row>
        </PaperSheet>
      </div>
    );
  }
}

export default ReportLayout;
