import Reflux from 'reflux';
import { get } from 'lodash';

import URLUtils from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';

const licenseStatusUrl = '/plugins/org.graylog.plugins.license/licenses/status/for-subject?subject=/license/enterprise/views';

const ViewsLicenseActions = Reflux.createActions({ status: { asyncResult: true } });
const ViewsLicenseStore = Reflux.createStore({
  listenables: [ViewsLicenseActions],

  getInitialState() {
    return this._status;
  },

  init() {
    this._status = {
      status: undefined,
      missing: true,
      loading: true,
    };

    this.status();
  },

  status() {
    const promise = fetch('GET', URLUtils.qualifyUrl(licenseStatusUrl))
      .then(
        (response) => {
          this._status = { status: response.status, missing: false, loading: false };

          return this._status;
        },
        (error) => {
          const status = get(error, 'additional.status');

          if (status === 404) {
            this._status = { status: undefined, missing: true, loading: false };
          } else {
            const errorMessage = get(error, 'additional.body.message') || get(error, 'message');

            this._status = { status: undefined, missing: true, loading: false, error: errorMessage };
          }

          return this._status;
        },
      );

    promise.then((result) => this.trigger(result));

    ViewsLicenseActions.status.promise(promise);

    return promise;
  },
});

export { ViewsLicenseStore, ViewsLicenseActions };
