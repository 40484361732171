// @flow strict
import Reflux from 'reflux';
import * as Immutable from 'immutable';

import type { WizardSubmitPayload } from 'logic/authentication/directoryServices/types';
import { singletonActions } from 'views/logic/singleton';
import type { RefluxActions } from 'stores/StoreTypes';

import GroupSyncBackend, { type GroupSyncBackendJSON } from '../../logic/directoryServices/GroupSyncBackend';
import Group from '../../logic/directoryServices/Group';

export type LoadGroupsResponse = {
  success: boolean,
  message: string,
  errors: Immutable.List<string>,
  list: Immutable.List<Group>,
};

export type LoadBackendResponse = {
  backend: GroupSyncBackend,
};

export type LoadGroupsPayload = {
  auth_service_backend_id: ?string,
  auth_service_backend_configuration: WizardSubmitPayload,
  team_sync_backend_configuration: {...$ObjMap<GroupSyncBackendJSON, <V>() => ?V>, id?: string},
};

export type ActionsType = {
  create: (backendId: string, payload: $ObjMap<GroupSyncBackendJSON, <V>() => ?V>) => Promise<?LoadBackendResponse>,
  delete: (backendId: string) => Promise<void>,
  load: (backendId: string) => Promise<?LoadBackendResponse>,
  loadGroups: (payload: LoadGroupsPayload) => Promise<LoadGroupsResponse>,
  update: (backendId: string, payload: $ObjMap<GroupSyncBackendJSON, <V>() => ?V>) => Promise<?LoadBackendResponse>,
};

const GroupSynchronizationActions: RefluxActions<ActionsType> = singletonActions(
  'enterprise.authentication.directoryService.GroupSync',
  () => Reflux.createActions({
    create: { asyncResult: true },
    delete: { asyncResult: true },
    load: { asyncResult: true },
    loadGroups: { asyncResult: true },
    update: { asyncResult: true },
  }),
);

export default GroupSynchronizationActions;
