// @flow strict
import notifyingAction from 'domainActions/notifyingAction';

import type { ActionsType } from '../actions/TeamsActions';
import { TeamsActions } from '../stores/TeamsStore';

const create: $PropertyType<ActionsType, 'create'> = notifyingAction({
  action: TeamsActions.create,
  success: (team) => ({
    message: `Team "${team?.name}" was created successfully`,
  }),
  error: (error, team) => ({
    message: `Creating team "${team?.name}" failed with status: ${error}`,
  }),
});

const load: $PropertyType<ActionsType, 'load'> = notifyingAction({
  action: TeamsActions.load,
  error: (error, teamId) => ({
    message: `Loading team with id "${teamId}" failed with status: ${error}`,
  }),
  notFoundRedirect: true,
});

const update: $PropertyType<ActionsType, 'update'> = notifyingAction({
  action: TeamsActions.update,
  success: (team) => ({
    message: `Team "${team.name}" was updated successfully`,
  }),
  error: (error, team) => ({
    message: `Updating team "${team.name}" failed with status: ${error}`,
  }),
});

const deleteAction: $PropertyType<ActionsType, 'delete'> = notifyingAction({
  action: TeamsActions.delete,
  success: (team) => ({
    message: `Team "${team.name}" was deleted successfully`,
  }),
  error: (error, team) => ({
    message: `Deleting team "${team.name}" failed with status: ${error}`,
  }),
});

const addMembers: $PropertyType<ActionsType, 'addMembers'> = notifyingAction({
  action: TeamsActions.addMembers,
  success: (teamId, userIds) => ({
    message: `${userIds.size} users were assigned successfully`,
  }),
  error: (error, teamId, userIds) => ({
    message: `Assigning ${userIds.size} users failed with status: ${error}`,
  }),
});

const addMemberToTeams: $PropertyType<ActionsType, 'addMemberToTeams'> = notifyingAction({
  action: TeamsActions.addMemberToTeams,
  success: () => ({
    message: 'User was assigned successfully',
  }),
  error: (error, userId) => ({
    message: `Assigning user with id "${userId}" failed with status: ${error}`,
  }),
});

const addRoleToTeams: $PropertyType<ActionsType, 'addRoleToTeams'> = notifyingAction({
  action: TeamsActions.addRoleToTeams,
  success: () => ({
    message: 'Teams were assigned to role successfully',
  }),
  error: (error, roleId) => ({
    message: `Assigning team to role with id "${roleId}" failed with status: ${error}`,
  }),
});

const removeMember: $PropertyType<ActionsType, 'removeMember'> = notifyingAction({
  action: TeamsActions.removeMember,
  success: () => ({
    message: 'User was unassigned successfully',
  }),
  error: (error) => ({
    message: `Unassign user failed with status: ${error}`,
  }),
});

const removeRole: $PropertyType<ActionsType, 'removeRole'> = notifyingAction({
  action: TeamsActions.removeRole,
  success: () => ({
    message: 'Team was unassigned from role successfully',
  }),
  error: (error) => ({
    message: `Unassign team form role failed with from status: ${error}`,
  }),
});

const loadUsersForTeam: $PropertyType<ActionsType, 'loadUsersForTeam'> = notifyingAction({
  action: TeamsActions.loadUsersForTeam,
  error: (error, teamId, teamName) => ({
    message: `Loading users for team "${teamName}" failed with status: ${error}`,
  }),
});

const loadRolesForTeam: $PropertyType<ActionsType, 'loadRolesForTeam'> = notifyingAction({
  action: TeamsActions.loadRolesForTeam,
  error: (error, teamId, teamName) => ({
    message: `Loading roles for team "${teamName}" failed with status: ${error}`,
  }),
});

const loadTeamsForUser: $PropertyType<ActionsType, 'loadTeamsForUser'> = notifyingAction({
  action: TeamsActions.loadTeamsForUser,
  error: (error, userId) => ({
    message: `Loading teams for user "${userId}" failed with status: ${error}`,
  }),
});

const loadTeamsForRole: $PropertyType<ActionsType, 'loadTeamsForRole'> = notifyingAction({
  action: TeamsActions.loadTeamsForRole,
  error: (error, userId) => ({
    message: `Loading teams for user "${userId}" failed with status: ${error}`,
  }),
});

const loadTeamsPaginated: $PropertyType<ActionsType, 'loadTeamsPaginated'> = notifyingAction({
  action: TeamsActions.loadTeamsPaginated,
  error: (error) => ({
    message: `Loading teams failed with status: ${error}`,
  }),
});

const loadTeamSharesPaginated: $PropertyType<ActionsType, 'loadTeamSharesPaginated'> = notifyingAction({
  action: TeamsActions.loadTeamSharesPaginated,
  error: (error, teamId) => ({
    message: `Loading entities which got shared with team with id "${teamId}" failed with status: ${error}`,
  }),
});

export default {
  create,
  load,
  update,
  delete: deleteAction,
  addMembers,
  addMemberToTeams,
  addRoleToTeams,
  removeMember,
  removeRole,
  loadUsersForTeam,
  loadRolesForTeam,
  loadTeamsForUser,
  loadTeamsForRole,
  loadTeamsPaginated,
  loadTeamSharesPaginated,
};
