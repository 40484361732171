import * as React from 'react';
import * as Immutable from 'immutable';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { LinkContainer } from 'components/graylog/router';

import { Button, ButtonToolbar, Col, ControlLabel, FormGroup, HelpBlock, Row } from 'components/graylog';
import { MultiSelect } from 'components/common';
import { Input } from 'components/bootstrap';
import FormsUtils from 'util/FormsUtils';
import history from 'util/History';
import Routes from 'routing/Routes';
import CombinedProvider from 'injection/CombinedProvider';
import { UsersActions } from 'stores/users/UsersStore';

import RecurringEventControl from '../common/RecurringEventControl';
import ReportsActions from '../ReportsActions';

class ReportScheduling extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = this._initState(this.props.report);
  }

  componentDidMount() {
    UsersActions.loadUsers().then((users) => {
      this.setState({ users: users });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.report.id !== nextProps.report.id) {
      this.setState(this._initState(nextProps.report));
    }
  }

  _initState = (report) => {
    const { scheduling } = report;

    return {
      scheduling: {
        active: scheduling.active,
        frequency: scheduling.frequency,
        frequency_configuration: scheduling.frequency_configuration,
        email_subject: scheduling.email_subject || '',
        email_body: scheduling.email_body || '',
        user_receivers: scheduling.user_receivers,
        email_receivers: scheduling.email_receivers,
      },
      users: Immutable.List(),
      dataTouched: false,
    };
  };

  _propagateUpdate = (updatedScheduling) => {
    this.setState({ scheduling: updatedScheduling, dataTouched: true });
  };

  _updateReport = (key, value) => {
    const updatedScheduling = lodash.cloneDeep(this.state.scheduling);

    if (Array.isArray(key)) {
      key.forEach((k, idx) => {
        updatedScheduling[k] = value[idx];
      });
    } else {
      updatedScheduling[key] = value;
    }

    this._propagateUpdate(updatedScheduling);
  };

  _handleInputChange = (event) => {
    this._updateReport(event.target.name, FormsUtils.getValueFromInput(event.target));
  };

  _handleMultiSelectChange = (name) => {
    return (value) => {
      this._updateReport(name, value === '' ? [] : value.split(','));
    };
  };

  _handleRecurringControlChange = (update) => {
    this._updateReport(lodash.keys(update), lodash.values(update));
  };

  _saveScheduling = (event) => {
    event.preventDefault();

    ReportsActions.updateScheduling(this.props.report.id, this.state.scheduling).then(() => {
      history.push(Routes.pluginRoute('REPORTS'));
    });
  };

  _onCancel = (event) => {
    if (!this.state.dataTouched) {
      return;
    }

    if (!window.confirm('Do you really want to abandon this page and lose your changes? This action cannot be undone.')) {
      event.preventDefault();
    }
  };

  render() {
    const { scheduling } = this.state;
    const usernames = this.state.users.map((user) => {
      return { value: user.username, label: `${user.username} (${user.fullName})` };
    });

    return (
      <Row>
        <Col md={12}>
          <h3>Scheduling</h3>
          <p>Choose the recipients for this report and when the report should be scheduled for delivery.</p>
          <form onSubmit={this._saveScheduling}>
            <Input id="active"
                   name="active"
                   type="checkbox"
                   label="Send this report automatically on a regular basis"
                   checked={scheduling.active}
                   onChange={this._handleInputChange} />
            <RecurringEventControl disabled={!scheduling.active}
                                   frequency={scheduling.frequency}
                                   frequency_configuration={scheduling.frequency_configuration || {}}
                                   onChange={this._handleRecurringControlChange} />
            <Input id="email_subject"
                   name="email_subject"
                   type="text"
                   label="Email subject"
                   help="Set an email subject to use when sending the report."
                   value={scheduling.email_subject}
                   onChange={this._handleInputChange}
                   disabled={!scheduling.active}
                   required />
            <Input id="email_body"
                   name="email_body"
                   type="textarea"
                   label="Email body"
                   help="Add an email body to use when sending the report."
                   value={scheduling.email_body}
                   onChange={this._handleInputChange}
                   disabled={!scheduling.active}
                   rows={6}
                   required />
            <FormGroup id="user-recipients-select">
              <ControlLabel>User recipients</ControlLabel>
              <MultiSelect id="user_receivers"
                           value={Array.isArray(scheduling.user_receivers) ? scheduling.user_receivers.join(',') : ''}
                           placeholder="Type username"
                           options={usernames}
                           onChange={this._handleMultiSelectChange('user_receivers')}
                           disabled={!scheduling.active} />
              <HelpBlock>Select Graylog usernames that will receive this report.</HelpBlock>
            </FormGroup>
            <FormGroup id="email-recipients-select">
              <ControlLabel>Email recipients</ControlLabel>
              <MultiSelect id="email_receivers"
                           value={Array.isArray(scheduling.email_receivers) ? scheduling.email_receivers.join(',') : ''}
                           addLabelText={'Add email "{label}"?'}
                           placeholder="Type email address"
                           options={[]}
                           onChange={this._handleMultiSelectChange('email_receivers')}
                           disabled={!scheduling.active}
                           allowCreate />
              <HelpBlock>Add email addresses that will receive this report.</HelpBlock>
            </FormGroup>

            <ButtonToolbar>
              <Button type="submit" bsStyle="success">Update scheduling</Button>
              <LinkContainer to={Routes.pluginRoute('REPORTS')} onClick={this._onCancel}>
                <Button className="cancel">Cancel</Button>
              </LinkContainer>
            </ButtonToolbar>
          </form>
        </Col>
      </Row>
    );
  }
}

export default ReportScheduling;
