// @flow strict
import type { WizardSubmitPayload } from 'logic/authentication/directoryServices/types';
import type { WizardFormValues } from 'components/authentication/directoryServices/BackendWizard/BackendWizardContext';

import prepareGroupSyncConfig from './prepareGroupSyncConfig';

import type { LoadGroupsPayload } from '../../actions/directoryServices/GroupSyncActions';

const prepareLoadGroupsPayload = (prepareAuthBackendPayload: () => WizardSubmitPayload, formValues: WizardFormValues, backendId: ?string, serviceType: string): LoadGroupsPayload => {
  const teamSyncAuthBackendId = backendId ?? '<test>';
  const directoryServiceConfig = prepareAuthBackendPayload();
  const groupSyncConfig = prepareGroupSyncConfig(formValues, teamSyncAuthBackendId, serviceType);

  return ({
    auth_service_backend_id: backendId,
    auth_service_backend_configuration: {
      ...directoryServiceConfig,
    },
    team_sync_backend_configuration: groupSyncConfig,
  });
};

export default prepareLoadGroupsPayload;
