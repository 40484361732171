import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'components/graylog';
import { DocumentTitle, PageHeader } from 'components/common';
import connect from 'stores/connect';

import ReportContents from './ReportContents';

import HeaderToolbar from '../common/HeaderToolbar';
import ReportLicenseStatusContainer from '../common/validations/ReportLicenseStatusContainer';
import ReportLicenseActions from '../ReportLicenseActions';
import ReportLicenseStore from '../ReportLicenseStore';
import { isValidLicense } from '../logic/license';

class ReportContentsPage extends React.Component {
  static propTypes = {
    licenseStatus: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    ReportLicenseActions.getLicenseStatus();
  }

  render() {
    const { licenseStatus } = this.props;

    return (
      <DocumentTitle title="New report">
        <div>
          <PageHeader title="New report">
            <span>
              Decide which content should be part of the report while you see its preview. You can schedule and
              configure the report in the next screen.
            </span>
            {null}
            <HeaderToolbar active="REPORTS_NEW" licenseStatus={licenseStatus} />
          </PageHeader>

          <ReportLicenseStatusContainer message="Creating new report definitions is disabled because there is no valid license." />

          {isValidLicense(licenseStatus) && (
            <Row className="content">
              <Col md={12}>
                <ReportContents key="new" action="create" />
              </Col>
            </Row>
          )}
        </div>
      </DocumentTitle>
    );
  }
}

export default connect(ReportContentsPage, { licenseStatus: ReportLicenseStore });
