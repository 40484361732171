import CustomizationPage from './CustomizationPage';
import HeaderBadge from './HeaderBadge';

export default {
  navigationItems: [{
    key: 'headerBadge',
    component: HeaderBadge,
  }],
  routes: [
    { path: '/customization', component: CustomizationPage, permissions: 'inputs:create' },
  ],
};
