// @flow strict
import * as React from 'react';

import Routes from 'routing/Routes';
import { Link } from 'components/graylog/router';
import { Alert, Col, Row } from 'components/graylog';
import { Spinner } from 'components/common';
import SectionGrid from 'components/common/Section/SectionGrid';
import SectionComponent from 'components/common/Section/SectionComponent';

import ProfileSection from './ProfileSection';
import UsersSection from './UsersSection';
import RolesSection from './RolesSection';

import LicenseCheck from '../../../license/LicenseCheck';
import Team from '../../logic/Team';

const _getAuthServiceLink = (authServiceId) => {
  const name = 'authentication service';

  if (authServiceId) {
    return <Link to={Routes.SYSTEM.AUTHENTICATION.BACKENDS.show(authServiceId)}>{name}</Link>;
  }

  return name;
};

type Props = {
  team: ?Team,
};

const TeamEdit = ({ team }: Props) => {
  if (!team) {
    return <Spinner />;
  }

  return (
    <LicenseCheck featureName="teams" displayWarningContainer>
      {({ licenseIsValid }) => {
        if (!licenseIsValid) {
          return null;
        }

        return (
          <SectionGrid>
            <div>
              { !team.isSynced && <ProfileSection team={team} />}
              { team.isSynced && (
                <SectionComponent title="Synced Team">
                  <Alert bsStyle="warning">
                    This is a synced team. Synced teams come from an external server and must be there for edited
                    there. Only roles can be edited on a synced teams. Please ask your administrator for more information.
                  </Alert>
                </SectionComponent>
              )}
            </div>
            <div>
              { !team.isSynced && <UsersSection team={team} /> }
              <RolesSection team={team} />
            </div>
          </SectionGrid>
        );
      }}
    </LicenseCheck>
  );
};

export default TeamEdit;
