import PropTypes from 'prop-types';
import React from 'react';
import naturalSort from 'javascript-natural-sort';

import { ButtonGroup, Button, ControlLabel, FormGroup, HelpBlock } from 'components/graylog';
import { Input } from 'components/bootstrap';
import ArchiveLicenseStatusContainer from 'archive/components/ArchiveLicenseStatusContainer';

class ArchiveRetentionStrategyConfiguration extends React.Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
    jsonSchema: PropTypes.object.isRequired,
    updateConfig: PropTypes.func.isRequired,
  };

  state = {
    max_number_of_indices: this.props.config.max_number_of_indices,
    index_action: this.props.config.index_action,
  };

  _onInputUpdate = (field) => {
    return (e) => {
      const update = { index_action: this.state.index_action };
      update[field] = e.target.value;

      this.setState(update);
      this.props.updateConfig(update);
    };
  };

  _setIndexAction = (type) => {
    return () => {
      this.setState({ index_action: type });
      this.props.updateConfig({ index_action: type, max_number_of_indices: this.state.max_number_of_indices });
    };
  };

  _indexActionActive = (type) => {
    return this.state.index_action === type;
  };

  _indexActions = () => {
    const indexActions = this.props.jsonSchema.properties.index_action.enum;

    indexActions.sort((a, b) => naturalSort(a.toLowerCase(), b.toLowerCase()));

    return indexActions;
  };

  render() {
    // Get all available index actions from the JSON schema.
    const buttons = this._indexActions().map((action) => {
      return (<Button key={`button-${action}`} onClick={this._setIndexAction(action)} active={this._indexActionActive(action)}>{action}</Button>);
    });

    return (
      <div>
        <ArchiveLicenseStatusContainer summary />
        <p>
          The archive index retention strategy creates an archive for an index before deleting or closing it.
        </p>
        <fieldset>
          <Input type="number"
                 id="max-number-of-indices"
                 label="Max number of indices"
                 onChange={this._onInputUpdate('max_number_of_indices')}
                 value={this.state.max_number_of_indices}
                 help={<span>Maximum number of indices to keep before <strong>archiving</strong> the oldest ones</span>}
                 autoFocus
                 required />
        </fieldset>
        <FormGroup id="action-after-archive">
          <ControlLabel>Action after archiving index</ControlLabel>
          <div><ButtonGroup>{buttons}</ButtonGroup></div>
          <HelpBlock>Please select which action should be performed once an index has been archived.</HelpBlock>
        </FormGroup>
      </div>
    );
  }
}

export default ArchiveRetentionStrategyConfiguration;
