// @flow strict
import * as React from 'react';
import { useFormikContext } from 'formik';

import { Input } from 'components/bootstrap';
import { FormikFormGroup } from 'components/common';

type Props = {
  disabled: boolean,
};

const SynchronizeGroupsCheckbox = ({ disabled }: Props) => {
  const { values, setErrors } = useFormikContext();

  const _onChange = (event) => {
    if (values.synchronizeGroups && !event.target.checked) {
      setErrors({});
    }
  };

  return (
    <Input label="Enable Group Synchronization"
           id="synchronize-groups-checkbox"
           disabled={disabled}
           labelClassName="col-sm-3"
           wrapperClassName="col-sm-9">
      <FormikFormGroup label="Synchronize Groups"
                       onChange={_onChange}
                       disabled={disabled}
                       wrapperClassName="col-sm-12"
                       name="synchronizeGroups"
                       help="Select this option if you want to import groups as teams"
                       type="checkbox" />
    </Input>
  );
};

export default SynchronizeGroupsCheckbox;
