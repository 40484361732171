// @flow strict
import type { WizardFormValues } from 'components/authentication/directoryServices/BackendWizard/BackendWizardContext';

import GroupSyncSection from './components/directoryServices/GroupSyncSection';
import MatchingGroupsProvider from './components/directoryServices/MatchingGroupsProvider';
import SyncedTeamsSection from './components/SyncedTeamsSection';
import useInitialGroupSyncValues from './components/directoryServices/useInitialGroupSyncValues';
import GroupSyncForm, { formValidation as GroupSyncValidation } from './components/directoryServices/GroupSyncForm';
import { GroupSyncActions } from './stores/directoryServices/GroupSyncStore';
import groupSyncWizardConfigLdap from './components/directoryServices/ldap/groupSyncWizardConfig';
import groupSyncWizardConfigAd from './components/directoryServices/activeDirectory/groupSyncWizardConfig';
import prepareGroupSyncConfig from './logic/directoryServices/prepareGroupSyncConfig';

const onDirectoryServiceBackendUpdate = (backendGroupSyncIsActive: boolean, formValues: WizardFormValues, backendId: string, serviceType: string) => {
  if (formValues.synchronizeGroups) {
    const payload = {
      ...prepareGroupSyncConfig(formValues, backendId, serviceType),
      auth_service_backend_id: backendId,
    };

    if (backendGroupSyncIsActive) {
      return GroupSyncActions.update(backendId, payload);
    }

    return GroupSyncActions.create(backendId, payload);
  }

  if (backendGroupSyncIsActive) {
    return GroupSyncActions.delete(backendId);
  }

  return Promise.resolve();
};

export default {
  'authentication.enterprise.directoryServices.groupSync': {
    actions: {
      onDirectoryServiceBackendUpdate,
    },
    components: {
      GroupSyncSection,
      GroupSyncForm,
      MatchingGroupsProvider,
    },
    hooks: {
      useInitialGroupSyncValues,
    },
    validation: {
      GroupSyncValidation,
    },
    wizardConfig: {
      ldap: groupSyncWizardConfigLdap,
      activeDirectory: groupSyncWizardConfigAd,
    },
  },
  'authentication.enterprise': {
    components: {
      SyncedTeamsSection,
    },
  },
};
