import Reflux from 'reflux';
import URI from 'urijs';

import URLUtils from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import UserNotification from 'util/UserNotification';
import AuditLogActions from 'auditlog/AuditLogActions';
import StoreProvider from 'injection/StoreProvider';

const SessionStore = StoreProvider.getStore('Session');

const AuditLogStore = Reflux.createStore({
  listenables: [AuditLogActions],
  urlPrefix: '/plugins/org.graylog.plugins.auditlog',
  auditLogStatus: undefined,

  getInitialState() {
    return { auditLogStatus: this.auditLogStatus };
  },

  getExportURL(format, query) {
    const q = query ? `&query=${query}` : '';

    return new URI(URLUtils.qualifyUrl(`${this.urlPrefix}/entries/export/${format}?limit=10000${q}`))
      .username(SessionStore.getSessionId())
      .password('session')
      .toString();
  },

  status() {
    const url = URLUtils.qualifyUrl(`${this.urlPrefix}/status/all`);

    const promise = fetch('GET', url);

    promise.then(
      (response) => {
        this.auditLogStatus = response;
        this.trigger({ auditLogStatus: response });
      },
      (error) => {
        UserNotification.error(`Fetching audit log status failed with status: ${error.message}`,
          'Could not retrieve audit log status');
      },
    );

    AuditLogActions.status.promise(promise);
  },

  entriesPaginated(page, perPage) {
    const url = URLUtils.qualifyUrl(`${this.urlPrefix}/entries?page=${page}&per_page=${perPage}`);

    const promise = fetch('GET', url);

    promise.then(
      (response) => {
        this.trigger({ paginatedEntries: response });
      },
      (error) => {
        UserNotification.error(`Fetching audit log entries failed with status: ${error.message}`, 'Could not retrieve audit log entries');
      },
    );

    AuditLogActions.entriesPaginated.promise(promise);
  },

  searchPaginated(page, perPage, query) {
    const url = URLUtils.qualifyUrl(`${this.urlPrefix}/entries`);
    const data = { page: page, per_page: perPage, query: query };

    const promise = fetch('POST', url, data);

    promise.then(
      (response) => {
        this.trigger({ paginatedEntries: response });
      },
      (error) => {
        UserNotification.error(`Fetching audit log entries failed with status: ${error.message}`, 'Could not retrieve audit log entries');
      },
    );

    AuditLogActions.searchPaginated.promise(promise);
  },
});

export default AuditLogStore;
