// @flow strict
import * as React from 'react';
import { useState, useCallback } from 'react';

import SharedEntitiesOverview from 'components/permissions/SharedEntitiesOverview';
import SectionComponent from 'components/common/Section/SectionComponent';

import TeamsDomain from '../../domainActions/TeamsDomain';
import Team from '../../logic/Team';

type Props = {
  teamId: $PropertyType<Team, 'id'>,
};

const SharedEntitiesSection = ({ teamId }: Props) => {
  const [loading, setLoading] = useState(false);
  const _searchPaginated = useCallback((pagination) => TeamsDomain.loadTeamSharesPaginated(teamId, pagination), [teamId]);

  return (
    <SectionComponent title="Shared Entities" showLoading={loading}>
      <SharedEntitiesOverview setLoading={setLoading}
                              entityType="team"
                              searchPaginated={_searchPaginated} />
    </SectionComponent>
  );
};

export default SharedEntitiesSection;
