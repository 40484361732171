exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Gc9ud69u27qKOMb9Son3D {\n    float: right;\n    margin-top: 10px;\n}\n\n._3LB_8fo0ngKEgPMVgI-ns1 {\n    float: right;\n    margin-top: 10px;\n}\n\n._80SFGYr93R7AhIcnH1WoX .header {\n    font-size: 1rem; /* theme.fonts.size.body */\n    padding: 10px 0;\n    min-height: 20px;\n}\n\n._80SFGYr93R7AhIcnH1WoX:not(._3PKdwbVBYJgUrBIXOF5O9n) .description {\n    margin-left: 20px;\n}\n\n._80SFGYr93R7AhIcnH1WoX .form-group {\n    margin-bottom: 0;\n}\n\n._3PKdwbVBYJgUrBIXOF5O9n {\n    margin-left: 0;\n}\n", ""]);

// exports
exports.locals = {
	"headerComponentsWrapper": "Gc9ud69u27qKOMb9Son3D",
	"itemActionsWrapper": "_3LB_8fo0ngKEgPMVgI-ns1",
	"itemWrapper": "_80SFGYr93R7AhIcnH1WoX",
	"itemWrapperStatic": "_3PKdwbVBYJgUrBIXOF5O9n"
};