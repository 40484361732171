// @flow strict
import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import * as Immutable from 'immutable';
import styled from 'styled-components';

import { Col, ControlLabel, Form, Row } from 'components/graylog';
import connect from 'stores/connect';
import { SearchParameterActions, SearchParameterStore } from 'enterprise/parameters/stores/SearchParameterStore';
import { SearchExecutionStateActions } from 'views/stores/SearchExecutionStateStore';
import { getParameterBindingsAsMap } from 'views/logic/search/SearchExecutionState';
import ParameterType from 'views/logic/parameters/Parameter';
import type { ParameterBindings } from 'views/logic/search/SearchExecutionState';
import QueryEditModeContext from 'views/components/contexts/QueryEditModeContext';
import type { QueryEditMode } from 'views/components/contexts/QueryEditModeContext';

import Parameter from './Parameter';

type Props = {
  onChange: (string, any) => void,
  onExecute: () => void,
  parameters: Immutable.Set<ParameterType>,
  parameterBindings: ParameterBindings,
};

const handleDelete = (name: string) => SearchParameterActions.remove(name);

const handleEdit = (name: string, parameter: ParameterType) => SearchParameterActions.update(name, parameter);

const Container = styled.div`
  margin-bottom: 10px;
`;

const wrapperForMode = (mode: QueryEditMode) => {
  switch (mode) {
    // eslint-disable-next-line react/prop-types
    case 'query': return ({ children }) => (
      <Row className="content">
        <Col md={12}>
          {children}
        </Col>
      </Row>
    );
    // eslint-disable-next-line react/prop-types
    case 'widget': return ({ children }) => <Container>{children}</Container>;
    default: throw new Error(`Invalid query edit mode: ${mode}`);
  }
};

const Parameters = ({ onExecute, parameters, parameterBindings }: Props) => {
  if (parameters.size === 0) {
    return null;
  }

  const [bindings, setBindings] = useState(getParameterBindingsAsMap(parameterBindings));

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    return SearchExecutionStateActions.setParameterValues(bindings).then(() => onExecute());
  }, [onExecute, bindings]);

  const onBlur = useCallback(() => SearchExecutionStateActions.setParameterValues(bindings), [bindings]);

  const onChange = useCallback((name, value) => setBindings(bindings.set(name, value)), [bindings, setBindings]);

  const parameterFields = useMemo(() => parameters
    .sortBy((p1: ParameterType, p2: ParameterType) => p1.title.localeCompare(p2.title))
    .map((param) => (
      <Parameter key={param.name}
                 parameter={param}
                 binding={bindings.get(param.name)}
                 onBlur={onBlur}
                 onChange={onChange}
                 onDelete={handleDelete}
                 onEdit={handleEdit} />
    ))
    .valueSeq()
    .toJS(), [parameters, bindings]);

  const queryEditMode: QueryEditMode = useContext(QueryEditModeContext);
  const Wrapper = useMemo(() => wrapperForMode(queryEditMode), [queryEditMode]);

  return (
    <Wrapper>
      <Form inline onSubmit={handleSubmit}>
        <ControlLabel>Parameters</ControlLabel>
        {parameterFields}
        <input type="submit" style={{ display: 'none' }} />
      </Form>
    </Wrapper>
  );
};

Parameters.propTypes = {
  parameterBindings: ImmutablePropTypes.map.isRequired,
  parameters: ImmutablePropTypes.map.isRequired,
  onChange: PropTypes.func.isRequired,
  onExecute: PropTypes.func.isRequired,
};

export default connect(Parameters, { parameters: SearchParameterStore });
