exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".-jhz-Lds8IbOsbS4L2glh {\n    font-size: 14px;\n    margin-top: 15px;\n    width: 95%;\n}\n\n._19tXaQf1HfznO9jETH-5AZ {\n    white-space: pre-wrap;\n}\n\n._1xpu8P7I9inGAJVKc88gg9 img {\n    border: 1px #e3e3e3 solid;\n}", ""]);

// exports
exports.locals = {
	"reportPreview": "-jhz-Lds8IbOsbS4L2glh",
	"descriptionPreview": "_19tXaQf1HfznO9jETH-5AZ",
	"logo": "_1xpu8P7I9inGAJVKc88gg9"
};