exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".IAz1th4kc_KW_9uENwY7p {\n    margin-bottom: 15px;\n    line-height: 1.3em;\n}\n\n@media print {\n    .IAz1th4kc_KW_9uENwY7p {\n        margin-top: 15px;\n        page-break-after: avoid;\n    }\n}", ""]);

// exports
exports.locals = {
	"heading": "IAz1th4kc_KW_9uENwY7p"
};