// @flow strict
import * as React from 'react';
import { Field } from 'formik';

import Input from 'components/bootstrap/Input';

const NameFormGroup = () => (
  <Field name="name">
    {({ field: { value, onChange } }) => (
      <Input type="text"
             id="name"
             label="Name"
             required
             onChange={onChange}
             help="The name of the team"
             labelClassName="col-sm-3"
             wrapperClassName="col-sm-9"
             value={value ?? ''} />
    )}
  </Field>
);

export default NameFormGroup;
