// @flow strict
import * as React from 'react';
import PropTypes from 'prop-types';

import connect from 'stores/connect';
import { ViewMetadataStore } from 'views/stores/ViewMetadataStore';

import ParameterInputForm from './ParameterInputForm';

const RequiredParametersForViewForm = ({ onSubmit, parameters, viewMetadata }) => (
  <ParameterInputForm parameters={parameters}
                      onSubmit={onSubmit}
                      viewTitle={viewMetadata.title} />
);

RequiredParametersForViewForm.propTypes = {
  onSubmit: PropTypes.func,
  parameters: PropTypes.any.isRequired,
  viewMetadata: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

RequiredParametersForViewForm.defaultProps = {
  onSubmit: () => {},
};

export default connect(RequiredParametersForViewForm, { viewMetadata: ViewMetadataStore });
