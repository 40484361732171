exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._30YPWPuSHp-p8DJx2otSDA:first-of-type {\n    padding-top: 15px;\n    padding-left: 10px;\n    padding-right: 10px;\n}\n\n._30YPWPuSHp-p8DJx2otSDA:not(:first-of-type) {\n    padding-top: 5px;\n    padding-left: 10px;\n    padding-right: 10px;\n}\n\n", ""]);

// exports
exports.locals = {
	"licenseNotification": "_30YPWPuSHp-p8DJx2otSDA"
};