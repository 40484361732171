exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".jTC2i_wi9gXLGxcnw_Dqf {\n    margin-bottom: 10px;\n}\n\n.jTC2i_wi9gXLGxcnw_Dqf img {\n    border: 1px #e3e3e3 solid;\n}\n\n._13Ks0MqnwQ96Kojw9cgui_ {\n    margin-bottom: 10px;\n}", ""]);

// exports
exports.locals = {
	"logo": "jTC2i_wi9gXLGxcnw_Dqf",
	"removeLogo": "_13Ks0MqnwQ96Kojw9cgui_"
};