exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media print {\n    @page {\n        size: A4;\n        margin: 1in;\n    }\n\n    html body {\n        margin-top: 0;\n        font-family: serif;\n        font-size: 18px;\n    }\n\n    ._1_2vUIRUH3B46Tln2oNCOq {\n        width: 210mm;\n    }\n\n    ._1ozaRL9MM25FtVw3XleTcC {\n        padding-top: 30%;\n    }\n\n    ._1ozaRL9MM25FtVw3XleTcC h1 {\n        font-size: 2em;\n        text-align: center;\n        margin-bottom: 0.8em;\n    }\n\n    ._1ozaRL9MM25FtVw3XleTcC h2 {\n        color: #555 !important;\n        font-size: 1.6em;\n        text-align: center;\n    }\n\n    ._1rFfJiwGHolSLtjcOpJMDS h3,\n    ._1_2vUIRUH3B46Tln2oNCOq h3 {\n        font-size: 1.4em;\n        font-weight: bold;\n    }\n\n    ._1pRcnQPCp0rsvRU-Ac_RaV {\n        white-space: pre-wrap;\n    }\n\n    table._1ikze2gCJHLHYbA5XkpSno {\n        font-size: 0.75em;\n        min-width: 50%;\n        margin: 0 auto 30px auto;\n    }\n\n    table._1ikze2gCJHLHYbA5XkpSno td,\n    table._1ikze2gCJHLHYbA5XkpSno th {\n        border: 1px #ccc solid;\n        padding: 5px;\n    }\n\n    ._1rFfJiwGHolSLtjcOpJMDS {\n        margin-bottom: 30px;\n    }\n\n    ._1rFfJiwGHolSLtjcOpJMDS h3 {\n        margin-bottom: 10px;\n    }\n\n    #footer {\n        display: none;\n    }\n\n    ._1kIRml00040uFp2UnkckjP {\n        margin-top: 10px;\n        margin-bottom: 30px;\n    }\n\n    ._1R76iNP-8M4ADLy3y3_Ysu {\n        page-break-after: always;\n    }\n\n    ._3P0i3-kBkVZU35txuwP2Rt {\n        margin-left: auto;\n        margin-top: 100px;\n        padding: 10px;\n        text-align: center;\n    }\n\n    ._3P0i3-kBkVZU35txuwP2Rt img {\n        max-height: 150px;\n        max-width: 150px;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"reportPage": "_1_2vUIRUH3B46Tln2oNCOq",
	"coverPage": "_1ozaRL9MM25FtVw3XleTcC",
	"parametersWarning": "_1rFfJiwGHolSLtjcOpJMDS",
	"description": "_1pRcnQPCp0rsvRU-Ac_RaV",
	"parameters": "_1ikze2gCJHLHYbA5XkpSno",
	"visualization": "_1kIRml00040uFp2UnkckjP",
	"pageBreak": "_1R76iNP-8M4ADLy3y3_Ysu",
	"logo": "_3P0i3-kBkVZU35txuwP2Rt"
};