import connect from 'stores/connect';
import { SearchMetadataStore } from 'views/stores/SearchMetadataStore';

import ParameterBar from './ParameterBar';

const ParameterBarWithUndeclaredParameters = connect(
  ParameterBar,
  { searchMetadata: SearchMetadataStore },
  ({ searchMetadata }) => ({ undeclaredParameters: searchMetadata.undeclared }),
);

export default ParameterBarWithUndeclaredParameters;
