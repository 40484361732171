import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Reflux from 'reflux';

import { Col, Row } from 'components/graylog';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import withParams from 'routing/withParams';

import ReportHistory from './ReportHistory';

import ReportHeaderToolbar from '../common/ReportHeaderToolbar';
import ReportValidations from '../common/ReportValidations';
import ReportsStore from '../ReportsStore';
import ReportsActions from '../ReportsActions';

const ReportHistoryPage = createReactClass({
  displayName: 'ReportHistoryPage',

  propTypes: {
    params: PropTypes.object.isRequired,
  },

  mixins: [Reflux.connect(ReportsStore)],

  componentDidMount() {
    this._loadReport(this.props.params.reportId);
  },

  componentWillReceiveProps(nextProps) {
    const nextReportId = nextProps.params.reportId;

    if (this.props.params.reportId !== nextReportId) {
      this._loadReport(nextReportId);
    }
  },

  _loadReport(reportId) {
    ReportsActions.get(reportId);
  },

  render() {
    const { report } = this.state;
    const isLoading = !report;

    if (isLoading) {
      return <Spinner text="Loading report data, please wait..." />;
    }

    return (
      <DocumentTitle title={`Report ${report.title} history`}>
        <div>
          <PageHeader title={<span>Report <em>{report.title}</em> history</span>}>
            <span>
              Review the generation and delivery status of any report.
            </span>
            {null}
            <ReportHeaderToolbar report={report} active="REPORTS_REPORTID_HISTORY" />
          </PageHeader>

          <ReportValidations />

          <Row className="content">
            <Col md={12}>
              <ReportHistory report={report} />
            </Col>
          </Row>
        </div>
      </DocumentTitle>
    );
  },
});

export default withParams(ReportHistoryPage);
