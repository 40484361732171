const BackendTypes = {
  backendTypes: [
    { value: 'fs-1', label: 'File System' },
  ],

  getBackendType(backendType) {
    return this.backendTypes.filter((t) => t.value === backendType)[0];
  },
};

export default BackendTypes;
