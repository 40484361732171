// @flow strict
import * as Immutable from 'immutable';

type InternalState = {|
  id: string,
  authServiceBackendId: string,
  selectionType: string,
  selection: Immutable.Set<string>,
  defaultRoles: Immutable.Set<string>,
  config: {
    type: string,
    groupSearchBase: string,
    groupSearchPattern: string,
    teamUniqueIdAttribute: string,
    teamNameAttribute: string,
  },
|};

export type GroupSyncBackendJSON = {
  id: string,
  auth_service_backend_id: string,
  selection_type: string,
  selection: Array<string>,
  default_roles: Array<string>,
  config: {
    type: string,
    group_search_base: string,
    group_search_pattern: string,
    team_unique_id_attribute: string,
    team_name_attribute: string,
  },
};

export default class GroupSyncBackend {
  _value: InternalState;

  constructor(
    id: $PropertyType<InternalState, 'id'>,
    authServiceBackendId: $PropertyType<InternalState, 'authServiceBackendId'>,
    selectionType: $PropertyType<InternalState, 'selectionType'>,
    selection: $PropertyType<InternalState, 'selection'>,
    defaultRoles: $PropertyType<InternalState, 'defaultRoles'>,
    config: $PropertyType<InternalState, 'config'>,
  ) {
    this._value = {
      id,
      authServiceBackendId,
      selectionType,
      selection,
      defaultRoles,
      config,
    };
  }

  get id(): $PropertyType<InternalState, 'id'> {
    return this._value.id;
  }

  get authServiceBackendId(): $PropertyType<InternalState, 'authServiceBackendId'> {
    return this._value.authServiceBackendId;
  }

  get selectionType(): $PropertyType<InternalState, 'selectionType'> {
    return this._value.selectionType;
  }

  get selection(): $PropertyType<InternalState, 'selection'> {
    return this._value.selection;
  }

  get defaultRoles(): $PropertyType<InternalState, 'defaultRoles'> {
    return this._value.defaultRoles;
  }

  get config(): $PropertyType<InternalState, 'config'> {
    return this._value.config;
  }

  // eslint-disable-next-line no-use-before-define
  toBuilder(): Builder {
    const {
      id,
      authServiceBackendId,
      selectionType,
      selection,
      defaultRoles,
      config,
    } = this._value;

    // eslint-disable-next-line no-use-before-define
    return new Builder(Immutable.Map({
      id,
      authServiceBackendId,
      selectionType,
      selection,
      defaultRoles,
      config,
    }));
  }

  toJSON() {
    const {
      id,
      authServiceBackendId,
      selectionType,
      selection = Immutable.Set(),
      defaultRoles = Immutable.Set(),
      config = {},
    } = this._value;

    return {
      id,
      auth_service_backend_id: authServiceBackendId,
      selection_type: selectionType,
      selection: selection.toJS(),
      default_roles: defaultRoles.toJS(),
      config: {
        type: config.type,
        group_search_base: config.groupSearchBase,
        team_unique_id_attribute: config.teamUniqueIdAttribute,
        team_name_attribute: config.teamNameAttribute,
      },
    };
  }

  static fromJSON(value: GroupSyncBackendJSON) {
    /* eslint-disable camelcase */
    const {
      id,
      auth_service_backend_id: authServiceBackendId,
      selection_type: selectionType,
      selection = [],
      default_roles: defaultRoles = [],
      config = {},
    } = value;

    /* eslint-enable camelcase */
    return new GroupSyncBackend(
      id,
      authServiceBackendId,
      selectionType,
      Immutable.Set(selection),
      Immutable.Set(defaultRoles),
      {
        type: config.type,
        groupSearchBase: config.group_search_base,
        groupSearchPattern: config.group_search_pattern,
        teamUniqueIdAttribute: config.team_unique_id_attribute,
        teamNameAttribute: config.team_name_attribute,
      },
    );
  }

  // eslint-disable-next-line no-use-before-define
  static builder(): Builder {
    // eslint-disable-next-line no-use-before-define
    return new Builder();
  }
}

type InternalBuilderState = Immutable.Map<string, any>;

class Builder {
  value: InternalBuilderState;

  constructor(value: InternalBuilderState = Immutable.Map()) {
    this.value = value;
  }

  id(value: $PropertyType<InternalState, 'id'>): Builder {
    return new Builder(this.value.set('id', value));
  }

  authServiceBackendId(value: $PropertyType<InternalState, 'authServiceBackendId'>): Builder {
    return new Builder(this.value.set('authServiceBackendId', value));
  }

  selectionType(value: $PropertyType<InternalState, 'selectionType'>): Builder {
    return new Builder(this.value.set('selectionType', value));
  }

  selection(value: $PropertyType<InternalState, 'selection'>): Builder {
    return new Builder(this.value.set('selection', value));
  }

  defaultRoles(value: $PropertyType<InternalState, 'defaultRoles'>): Builder {
    return new Builder(this.value.set('defaultRoles', value));
  }

  config(value: $PropertyType<InternalState, 'config'>): Builder {
    return new Builder(this.value.set('config', value));
  }

  build(): GroupSyncBackend {
    const {
      id,
      authServiceBackendId,
      selectionType,
      selection,
      defaultRoles,
      config,
    } = this.value.toObject();

    return new GroupSyncBackend(
      id,
      authServiceBackendId,
      selectionType,
      selection,
      defaultRoles,
      config,
    );
  }
}
