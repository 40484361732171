exports = module.exports = require("../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1y4MHV-iLBZpVCl1qcNMVR {\n    font-weight: bold;\n    margin-bottom: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"licenseStatusTitle": "_1y4MHV-iLBZpVCl1qcNMVR"
};