exports = module.exports = require("../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1X90iTAUJhTwurdCuaossx img {\n    max-width: 150px;\n    max-height: 150px;\n}", ""]);

// exports
exports.locals = {
	"logo": "_1X90iTAUJhTwurdCuaossx"
};