import PropTypes from 'prop-types';
import React from 'react';

import { Row, Col } from 'components/graylog';
import BootstrapModalForm from 'components/bootstrap/BootstrapModalForm';
import { Spinner } from 'components/common';
import ArchiveActions from 'archive/ArchiveActions';

class ArchiveCatalogExportModal extends React.Component {
  static propTypes = {
    query: PropTypes.string.isRequired,
  };

  state = { filenames: undefined };

  open = () => {
    this.modal.open();
  };

  close = () => {
    this.modal.close();
  };

  _onModalOpen = () => {
    ArchiveActions.exportFilenames(this.props.query)
      .then((response) => this.setState({ filenames: response.filenames }));
  };

  _onSubmit = (e) => {
    e.preventDefault();
    this.close();
  };

  _isLoading = () => {
    return !this.state.filenames;
  };

  _modalContent = () => {
    if (this._isLoading()) {
      return <Spinner text="Loading filenames..." />;
    }

    return (
      <Row>
        <Col md={12}>
          <textarea style={{ width: '100%', minHeight: '400px' }}
                    value={this.state.filenames.join('\n')}
                    readOnly />
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <BootstrapModalForm ref={(c) => { this.modal = c; }}
                          title="Catalog Filenames Export"
                          onModalOpen={this._onModalOpen}
                          onSubmitForm={this._onSubmit}
                          submitButtonText="Close">
        {this._modalContent()}
      </BootstrapModalForm>
    );
  }
}

export default ArchiveCatalogExportModal;
