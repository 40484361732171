exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2TYhHjYWUcUvPWUU7UWma4 .panel-title {\n    font-size: 1rem; /* theme.fonts.size.body */\n}\n\n._2TYhHjYWUcUvPWUU7UWma4 .panel-title > a {\n    cursor: pointer;\n    display: block;\n}\n\n._2TYhHjYWUcUvPWUU7UWma4 .panel-body {\n    padding: 10px;\n}\n\n._2TYhHjYWUcUvPWUU7UWma4 ul {\n    padding-left: 0;\n}\n", ""]);

// exports
exports.locals = {
	"helpPanel": "_2TYhHjYWUcUvPWUU7UWma4"
};