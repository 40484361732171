import React from 'react';
import createReactClass from 'create-react-class';
import Reflux from 'reflux';

import { Spinner } from 'components/common';
import ChecksumTypes from 'archive/logic/ChecksumTypes';
import CompressionTypes from 'archive/logic/CompressionTypes';
import NumberUtils from 'util/NumberUtils';
import ArchiveActions from 'archive/ArchiveActions';
import ArchiveStore from 'archive/ArchiveStore';

const ArchiveConfig = createReactClass({
  displayName: 'ArchiveConfig',
  mixins: [Reflux.connect(ArchiveStore)],

  componentDidMount() {
    ArchiveActions.config().then((config) => {
      ArchiveActions.getBackend(config.backend_id);
    });

    this.timerId = setInterval(() => {
      ArchiveActions.config().then((config) => {
        ArchiveActions.getBackend(config.backend_id);
      });
    }, this.timerInterval);

    this.style.use();
  },

  componentWillUnmount() {
    clearInterval(this.timerId);
    this.timerId = undefined;

    this.style.unuse();
  },

  style: require('!style/useable!css!archive/style.css'),
  timerId: undefined,
  timerInterval: 5000,

  render() {
    if (!this.state.config || !this.state.backend) {
      return <Spinner />;
    }

    const { config } = this.state;

    return (
      <div>
        <h2>Configuration</h2>
        <dl className="deflist deflistWide archive-config-overview">
          <dt>Backend:</dt>
          <dd>{this.state.backend.title} - {this.state.backend.settings.output_path}</dd>
          <dt>Max segment size:</dt>
          <dd>{NumberUtils.formatBytes(config.max_segment_size)}</dd>
          <dt>Compression type:</dt>
          <dd>{CompressionTypes.getCompressionType(config.segment_compression_type).label}</dd>
          <dt>Checksum type:</dt>
          <dd>{ChecksumTypes.getChecksumType(config.segment_checksum_type).label}</dd>
          <dt>Restore index batch size:</dt>
          <dd>{NumberUtils.formatNumber(config.restore_index_batch_size)}</dd>
        </dl>
      </div>
    );
  },
});

export default ArchiveConfig;
