// @flow strict
import * as React from 'react';

import { singleton } from 'views/logic/singleton';

import type { LoadGroupsResponse } from '../../actions/directoryServices/GroupSyncActions';

export type MatchingGroupsContextType = {
  result: ?$Shape<LoadGroupsResponse>,
  finishedLoading: boolean,
  setContextValue: ({ finishedLoading: boolean, result: ?$Shape<LoadGroupsResponse>}) => void,
};

export const initialState = {
  result: undefined,
  finishedLoading: false,
  setContextValue: () => {},
};

const MatchingGroupsContext = React.createContext<MatchingGroupsContextType>(initialState);
export default singleton('contexts.authentication.enterprise.ldap.groupSync', () => MatchingGroupsContext);
