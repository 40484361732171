import React from 'react';
import PropTypes from 'prop-types';

import style from './Caption.css';

class Caption extends React.Component {
  static propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  };

  render() {
    return (
      <p className={style.caption}>{this.props.text}</p>
    );
  }
}

export default Caption;
