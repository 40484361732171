exports = module.exports = require("../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._29Kqc-K2Ra4diF0TZWW1lu dt:after {\n    content: ':';\n}\n\n._2qQT6fifwxuDc9dvaWpt2H dt:after {\n    content: '';\n}\n\n.auditlog-status dl {\n    margin-top: 8px;\n}", ""]);

// exports
exports.locals = {
	"descriptionList": "_29Kqc-K2Ra4diF0TZWW1lu",
	"innerDl": "_2qQT6fifwxuDc9dvaWpt2H"
};