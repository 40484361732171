// @flow strict
import * as Immutable from 'immutable';
import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button } from 'components/graylog';
import { Select } from 'components/common';

import type { PaginatedTeams } from '../../actions/TeamsActions';
import Team from '../../logic/Team';
import TeamsDomain from '../../domainActions/TeamsDomain';

type Props = {
  onSubmit: (teamIds: Immutable.Set<$PropertyType<Team, 'id'>>) => Promise<void>,
  showCreationForm: () => void;
  getUnassignedTeams: (teams: Immutable.List<Team>) => Immutable.List<Team>,
};

const StyledButton = styled(Button)`
  margin-left: 15px;
`;

const FormElements = styled.div`
  display: flex;
`;

const TeamSelectOption = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  flex: 1;
`;

const _renderTeamOption = ({ label, value }: { label: string, value: string }) => (
  <TeamSelectOption value={value}>{label}</TeamSelectOption>
);

const _options = (teams, getUnassignedTeams) => getUnassignedTeams(teams)
  .toArray()
  .map((t) => ({ label: t.name, value: t.id }));

const _assignTeams = (onSubmit, selectedTeamIds, setSelectedTeamIds, setIsSubmitting) => {
  if (!selectedTeamIds) {
    return;
  }

  onSubmit(Immutable.Set(selectedTeamIds.split(','))).then(() => {
    setSelectedTeamIds();
    setIsSubmitting(false);
  });
};

const _loadTeams = (setPaginatedTeams) => {
  const getUnlimited = { page: 1, perPage: 0, query: '' };

  TeamsDomain.loadTeamsPaginated(getUnlimited).then(setPaginatedTeams);
};

const TeamsSelector = ({ getUnassignedTeams, onSubmit, showCreationForm }: Props) => {
  const [paginatedTeams, setPaginatedTeams] = useState<?PaginatedTeams>();
  const [selectedTeamIds, setSelectedTeamIds] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { list: teams } = paginatedTeams || { list: Immutable.List() };
  const _onSubmit = () => _assignTeams(onSubmit, selectedTeamIds, setSelectedTeamIds, setIsSubmitting);

  useEffect(() => _loadTeams(setPaginatedTeams), [showCreationForm]);

  return (
    <div>
      <FormElements>
        <StyledSelect inputProps={{ 'arial-label': 'Search for teams ' }}
                      onChange={setSelectedTeamIds}
                      optionRenderer={_renderTeamOption}
                      options={_options(teams, getUnassignedTeams)}
                      multi
                      placeholder="Search for teams"
                      value={selectedTeamIds} />
        <StyledButton bsStyle="success"
                      onClick={_onSubmit}
                      disabled={isSubmitting || !selectedTeamIds}
                      title="Assign user to team"
                      type="submit">
          Assign Team
        </StyledButton>
        <StyledButton bsStyle="info"
                      title="Quick Create team"
                      onClick={showCreationForm}>
          Quick Create Team
        </StyledButton>
      </FormElements>
    </div>
  );
};

export default TeamsSelector;
