// @flow strict
import notifyingAction from 'domainActions/notifyingAction';

import AuthenticationActions, { type ActionsType } from '../actions/AuthenticationActions';

const triggerTeamSync: $PropertyType<ActionsType, 'triggerTeamSync'> = notifyingAction({
  action: AuthenticationActions.triggerTeamSync,
  error: (error, authBackendId, backendTitle) => ({
    message: `Synchronizing teams for authentication service "${backendTitle}" failed with status: ${error}`,
  }),
});

export default {
  triggerTeamSync,
};
