exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ADsMw9AupxauCnuuMn7AE {\n    padding-left: 0;\n    margin-bottom: 20px;\n}\n\n.ADsMw9AupxauCnuuMn7AE .ADsMw9AupxauCnuuMn7AE {\n    margin-bottom: 0;\n}\n", ""]);

// exports
exports.locals = {
	"list": "ADsMw9AupxauCnuuMn7AE"
};