import React from 'react';
import createReactClass from 'create-react-class';
import Reflux from 'reflux';

import { Alert, DropdownButton, Button, MenuItem, OverlayTrigger, Popover, Table } from 'components/graylog';
import { Icon, PaginatedList, SearchForm } from 'components/common';
import AuditLogEntriesTable from 'auditlog/AuditLogEntriesTable';
import AuditLogActions from 'auditlog/AuditLogActions';
import AuditLogStore from 'auditlog/AuditLogStore';

const style = require('!style/useable!css!auditlog/AuditLogEntriesStyle.css');

const AuditLogEntries = createReactClass({
  displayName: 'AuditLogEntries',
  mixins: [Reflux.connect(AuditLogStore)],

  getInitialState() {
    return {
      paginatedEntries: undefined,
      currentPage: 1,
    };
  },

  componentDidMount() {
    AuditLogActions.entriesPaginated(1, this.DEFAULT_PAGE_SIZE);
    style.use();
  },

  componentWillUnmount() {
    style.unuse();
  },

  PAGE_SIZES: [20, 50, 100, 200, 500],
  DEFAULT_PAGE_SIZE: 20,

  _onPageChange(newPage, pageSize) {
    if (this.state.paginatedEntries && this.state.paginatedEntries.query) {
      AuditLogActions.searchPaginated(newPage, pageSize, this.state.paginatedEntries.query);
    } else {
      AuditLogActions.entriesPaginated(newPage, pageSize);
    }

    this.setState({ currentPage: newPage });
  },

  _onSearch(query, cb) {
    AuditLogActions.searchPaginated(1, this.DEFAULT_PAGE_SIZE, query).then(() => cb());
  },

  _onReset() {
    AuditLogActions.entriesPaginated(1, this.DEFAULT_PAGE_SIZE);
  },

  render() {
    const { paginatedEntries } = this.state;

    if (!paginatedEntries) {
      return (
        <Alert bsStyle="info">
          Loading audit log entries...
        </Alert>
      );
    }

    const popover = (
      <Popover id="search-query-help" className="popover-wide" title="Search Syntax Help">
        <p><strong>Available search fields</strong></p>
        <Table condensed>
          <thead>
            <tr>
              <th>Field</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>actor</td>
              <td>User that triggered the audit event</td>
            </tr>
            <tr>
              <td>namespace</td>
              <td>Namespace of the audit event; might be different in plugins</td>
            </tr>
            <tr>
              <td>object</td>
              <td>Object of the audit event; what has been changed</td>
            </tr>
            <tr>
              <td>action</td>
              <td>Name of the action that has been executed on the object</td>
            </tr>
            <tr>
              <td>success_status</td>
              <td>If the action failed or succeeded</td>
            </tr>
            <tr>
              <td>message</td>
              <td>The actual audit event message</td>
            </tr>
            <tr>
              <td>timestamp</td>
              <td>Timestamp of the audit event</td>
            </tr>
          </tbody>
        </Table>
        <p><strong>Examples</strong></p>
        <p>
          Find audit log messages created after the given date:<br />
          <kbd>{'timestamp:>=2017-03-23'}</kbd><br />
        </p>
        <p>
          Find audit log messages where the action is <tt>create</tt> or <tt>delete</tt>:<br />
          <kbd>action:create action:delete</kbd><br />
          <kbd>action:create,delete</kbd>
        </p>
      </Popover>
    );

    return (
      <div className="auditlog-entries">
        <h2>
          Audit Log Entries
          <span>&nbsp;<small>{paginatedEntries.total} total</small></span>
        </h2>

        <div className="auditlog-entries-content">
          <PaginatedList totalItems={paginatedEntries.total}
                         pageSize={this.DEFAULT_PAGE_SIZE}
                         pageSizes={this.PAGE_SIZES}
                         onChange={this._onPageChange}
                         activePage={paginatedEntries.page}>
            <SearchForm onSearch={this._onSearch} searchBsStyle="success" onReset={this._onReset} queryWidth={500} useLoadingState>
              <DropdownButton id="export-entries-dropdown" title="Export Results" style={{ marginLeft: 5 }}>
                <MenuItem href={AuditLogStore.getExportURL('json', this.state.paginatedEntries.query)}>JSON</MenuItem>
                <MenuItem href={AuditLogStore.getExportURL('csv', this.state.paginatedEntries.query)}>CSV</MenuItem>
              </DropdownButton>
              <OverlayTrigger trigger="click" rootClose placement="right" overlay={popover}>
                <Button bsStyle="link" className="archive-search-help-button">
                  <Icon name="question-circle" fixedWidth />
                </Button>
              </OverlayTrigger>
            </SearchForm>
            <AuditLogEntriesTable entries={paginatedEntries.entries} />
          </PaginatedList>
        </div>
      </div>
    );
  },
});

export default AuditLogEntries;
