import PropTypes from 'prop-types';
import React from 'react';
import lodash from 'lodash';

import { Select } from 'components/common';

class DaySelect extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: undefined,
    disabled: false,
    required: false,
  };

  render() {
    const { id, value, disabled, required, onChange } = this.props;

    const dow = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => {
      return { value: day, label: lodash.upperFirst(day) };
    });

    return (
      <Select id={id}
              options={dow}
              value={value}
              placeholder="Select day of the week"
              disabled={disabled}
              onChange={onChange}
              required={required} />
    );
  }
}

export default DaySelect;
