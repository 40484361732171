// @flow strict
import * as React from 'react';
import { useState, useCallback } from 'react';

import { PaginatedItemOverview } from 'components/common';
import SectionComponent from 'components/common/Section/SectionComponent';
import UsersQueryHelper from 'components/users/UsersQueryHelper';

import TeamsDomain from '../../domainActions/TeamsDomain';
import Team from '../../logic/Team';

type Props = {
  team: Team,
};

const UsersSection = ({ team: { id, name } }: Props) => {
  const [loading, setLoading] = useState(false);
  const usersQueryHelper = <UsersQueryHelper />;

  const _onLoad = useCallback((pagination) => {
    setLoading(true);

    return TeamsDomain.loadUsersForTeam(id, name, pagination).then((paginatedUsers) => {
      setLoading(false);

      return paginatedUsers;
    });
  }, [id, name]);

  return (
    <SectionComponent title="Users" showLoading={loading}>
      <PaginatedItemOverview noDataText="No selected users have been found."
                             onLoad={_onLoad}
                             queryHelper={usersQueryHelper} />
    </SectionComponent>
  );
};

export default UsersSection;
