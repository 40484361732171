// @flow strict
import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/graylog/router';
import Routes from 'routing/Routes';
import Panel from 'components/graylog/Panel';
import CombinedProvider from 'injection/CombinedProvider';
import { Spinner } from 'components/common';
import { Col, Row } from 'components/graylog';
import { LicensesActions } from './LicensesStore';
const { EnterpriseActions } = CombinedProvider.get('Enterprise');
type InvalidLicenseWarningProps = {
  featureName: ?string,
  displayWarningContainer: boolean,
};
const InvalidLicenseWarning = ({ featureName, displayWarningContainer }: InvalidLicenseWarningProps) => {
  const warning = (
    <Panel.Body>
      A valid enterprise license is needed to use {featureName ?? 'this'} functionality.<br />
      Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for
      more information or contact your Graylog account manager.
    </Panel.Body>
  );
  if (displayWarningContainer) {
    return (
      <Row className="content">
        <Col xs={12}>
          <Panel bsStyle="warning" className="no-bm">
            {warning}
          </Panel>
        </Col>
      </Row>
    )
  }
  return (<Panel bsStyle="warning">{warning}</Panel>);
};
type Props = {
  children: React.Node | React.ChildrenArray<React.Node> | ({ licenseIsValid: boolean }) => React.Node,
  displayLicenseWarning: boolean,
  featureName: ?string,
  displayWarningContainer: boolean
};
/**
 * Component which provides a `licenseIsValid` prop. E.g. to display a read only version of its children.
 */
const LicenseCheck = ({ children, featureName, displayLicenseWarning, displayWarningContainer }: Props) => {
  const [licenseIsValid, setLicenseIsValid] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    LicensesActions.listStatus().then((response) => {
      const status = response?.status ?? [];
      const valid = status.reduce((result, state) => result || state.valid, false);
      setLicenseIsValid(valid);
      setLoading(false);
    });
  }, []);
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      {(!licenseIsValid && displayLicenseWarning) && <InvalidLicenseWarning featureName={featureName} displayWarningContainer={displayWarningContainer} />}
      {typeof children === 'function' ? children({ licenseIsValid }) : children}
    </>
  );
};
LicenseCheck.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  displayLicenseWarning: PropTypes.bool,
  featureName: PropTypes.string,
  displayWarningContainer: PropTypes.element
};
LicenseCheck.defaultProps = {
  displayLicenseWarning: true,
  featureName: undefined,
  displayWarningContainer: false,
};
export default LicenseCheck;