exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tc2ZwgiKl2vrLBvU_PLdF {\n    color: #a6a6a6;\n    font-size: 0.8em;\n    margin: 10px auto 0 auto;\n    text-align: center;\n    width: 75%;\n}\n\n@media print {\n    .tc2ZwgiKl2vrLBvU_PLdF {\n        page-break-before: avoid;\n        page-break-inside: avoid;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"caption": "tc2ZwgiKl2vrLBvU_PLdF"
};