import React from 'react';
import createReactClass from 'create-react-class';
import naturalSort from 'javascript-natural-sort';

import Routes from 'routing/Routes';
import { Button, Col, Row } from 'components/graylog';
import { Input } from 'components/bootstrap';
import { Select, Spinner } from 'components/common';
import DocumentationLink from 'components/support/DocumentationLink';
import ChecksumTypes from 'archive/logic/ChecksumTypes';
import CompressionTypes from 'archive/logic/CompressionTypes';
import BackendTypes from 'archive/logic/BackendTypes';
import history from 'util/History';
import ArchiveConfigStreamSelectionForm from 'archive/components/ArchiveConfigStreamSelectionForm';
import ArchiveActions from 'archive/ArchiveActions';

const ArchiveConfigForm = createReactClass({
  displayName: 'ArchiveConfigForm',

  getInitialState() {
    return {
      config: undefined,
    };
  },

  componentDidMount() {
    this.style.use();
    ArchiveActions.config.triggerPromise().then((config) => this.setState({ config: config }));

    // TODO: Magic pagination number - backend selection should probably be a search form instead of a drop-down
    ArchiveActions.listBackends.triggerPromise(1, 1000).then((response) => {
      this.setState({ backends: response.backends });
    });
  },

  componentWillUnmount() {
    this.style.unuse();
  },

  style: require('!style/useable!css!archive/style.css'),

  _updateConfig(fieldName, value) {
    const { config } = this.state;
    config[fieldName] = value;
    this.setState({ config: config });
  },

  _onInputUpdate(fieldName) {
    return (e) => {
      this._updateConfig(fieldName, e.target.value);
    };
  },

  _onChecksumTypeSelect(selection) {
    if (selection === '') {
      return;
    }

    this._updateConfig('segment_checksum_type', selection);
  },

  _onCompressionTypeSelect(selection) {
    if (selection === '') {
      return;
    }

    this._updateConfig('segment_compression_type', selection);
  },

  _onBackendTypeSelect(selection) {
    if (selection === '') {
      return;
    }

    this._updateConfig('backend_id', selection);
  },

  _onExcludedStreamsChange(excludedStreams) {
    this._updateConfig('excluded_streams', excludedStreams);
  },

  _saveConfiguration(event) {
    event.preventDefault();

    ArchiveActions.saveConfig(this.state.config).then(() => {
      history.replace(Routes.pluginRoute('SYSTEM_ARCHIVES'));
    });
  },

  render() {
    if (!this.state.config || !this.state.backends) {
      return <Spinner />;
    }

    const { config } = this.state;

    const sortedBackends = this.state.backends.map((backend) => {
      return { value: backend.id, label: `${backend.title} (${BackendTypes.getBackendType(backend.settings.type).label})` };
    }).sort((a, b) => naturalSort(a.label.toLowerCase(), b.label.toLowerCase()));

    const compressionTypesHelpText = (
      <span>
        Compression method to apply on archive files. Read the{' '}
        <DocumentationLink page="archiving/setup.html#compression-type" text="documentation" />{' '}
        for more information on the different options.
      </span>
    );
    const checksumTypesHelpText = (
      <span>
        Checksum method to apply on archive files. Read the{' '}
        <DocumentationLink page="archiving/setup.html#checksum-type" text="documentation" />{' '}
        for more information on the different options.
      </span>
    );

    const sortedCompressionTypes = CompressionTypes.compressionTypes.sort((t1, t2) => naturalSort(t1.label.toLowerCase(), t2.label.toLowerCase()));

    return (
      <div>
        <h2>Archives configuration</h2>
        <form className="form form-horizontal archives-form" onSubmit={this._saveConfiguration}>
          <fieldset>
            <Input id="backend-select"
                   label="Backend"
                   required
                   autoFocus
                   help="In which backend should the archives be stored?"
                   labelClassName="col-sm-3"
                   wrapperClassName="col-sm-9">
              <Select placeholder="Select Backend"
                      required
                      clearable={false}
                      options={sortedBackends}
                      matchProp="label"
                      onChange={this._onBackendTypeSelect}
                      value={config.backend_id} />
            </Input>
            <Input type="text"
                   id="config-max-segment-size"
                   label="Max Segment Size"
                   onChange={this._onInputUpdate('max_segment_size')}
                   value={config.max_segment_size}
                   help={<span>Maximum size for each message segment in <strong>bytes</strong>.</span>}
                   labelClassName="col-sm-3"
                   wrapperClassName="col-sm-9"
                   required />
            <Input id="compresion-type-select"
                   label="Compression Type"
                   help={compressionTypesHelpText}
                   labelClassName="col-sm-3"
                   wrapperClassName="col-sm-9">
              <Select placeholder="Select Compression Type"
                      required
                      options={sortedCompressionTypes}
                      matchProp="label"
                      onChange={this._onCompressionTypeSelect}
                      value={CompressionTypes.getCompressionType(config.segment_compression_type).value} />
            </Input>
            <Input id="checksum-type-select"
                   label="Checksum Type"
                   help={checksumTypesHelpText}
                   labelClassName="col-sm-3"
                   wrapperClassName="col-sm-9">
              <Select placeholder="Select Checksum Type"
                      required
                      options={ChecksumTypes.checksumTypes}
                      matchProp="label"
                      onChange={this._onChecksumTypeSelect}
                      value={ChecksumTypes.getChecksumType(config.segment_checksum_type).value} />
            </Input>
            <Input type="text"
                   id="config-restore-index-batch-size"
                   label="Restore index batch size"
                   onChange={this._onInputUpdate('restore_index_batch_size')}
                   value={config.restore_index_batch_size}
                   help="When restoring an archive, what batch size should be used to re-import the data?"
                   labelClassName="col-sm-3"
                   wrapperClassName="col-sm-9"
                   required />
            <Input id="config-excluded-streams"
                   label="Streams to archive"
                   help="Select streams that should be included in the archive. New streams will be archived by default."
                   labelClassName="col-sm-3"
                   wrapperClassName="col-sm-9">
              <ArchiveConfigStreamSelectionForm excludedStreams={config.excluded_streams}
                                                updateExcludedStreams={this._onExcludedStreamsChange} />
            </Input>
            <Row>
              <Col smOffset={3} sm={9}>
                <Button type="submit" bsStyle="success">Update configuration</Button>
              </Col>
            </Row>
          </fieldset>
        </form>
      </div>
    );
  },
});

export default ArchiveConfigForm;
