exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".oIxaxEa8zuxqSjnR7UGed {\n    font-size: 90%;\n    height: 100%;\n    width: 100%;\n}\n\n._2b_tTGvrOWs_NjgEGVPyay {\n    width: 100%;\n}\n\n._2b_tTGvrOWs_NjgEGVPyay .quickvalues-table {\n    margin: 0 auto;\n    text-align: left;\n    width: 80%;\n}\n\n._2b_tTGvrOWs_NjgEGVPyay .dc-chart {\n    float: none;\n}\n\n._2Ch6hy7V3AKS188YrcGF6m,\n.rCqdFEcPF8fl_98xdLiNZ {\n    font-size: 70px;\n    line-height: 100px;\n    text-align: center;\n    vertical-align: middle;\n}\n\n._1o_qCCmVjukhPbvm6pwJWR {\n    font-size: 1.2em;\n    position: absolute;\n    left: 20px;\n    top: 20px;\n}\n\n@media print {\n    .oIxaxEa8zuxqSjnR7UGed {\n        padding: 0;\n        page-break-inside: avoid;\n    }\n\n    .AnIMOXBp1TTlEb3fvLyUa {\n        page-break-inside: avoid;\n        /* This is the desired behaviour, but currently unimplemented in Chrome. It may be implemented in a future version, so we leave it here. */\n        page-break-after: avoid;\n        break-after: avoid;\n    }\n\n    ._2b_tTGvrOWs_NjgEGVPyay {\n        /* This is the desired behaviour, but currently unimplemented in Chrome. It may be implemented in a future version, so we leave it here. */\n        page-break-after: avoid;\n        page-break-before: avoid;\n        break-after: avoid;\n        break-before: avoid;\n        margin-bottom: 4rem;\n    }\n\n    .GQqibyygT2Ow7bJd8R7-V {\n        page-break-inside: avoid;\n        /* This is the desired behaviour, but currently unimplemented in Chrome. It may be implemented in a future version, so we leave it here. */\n        page-break-before: avoid;\n        break-before: avoid;\n    }\n\n    ._1o_qCCmVjukhPbvm6pwJWR {\n        display: none;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"reportVisualization": "oIxaxEa8zuxqSjnR7UGed",
	"visualizationContainer": "_2b_tTGvrOWs_NjgEGVPyay",
	"notAvailable": "_2Ch6hy7V3AKS188YrcGF6m",
	"loading": "rCqdFEcPF8fl_98xdLiNZ",
	"draggableHandle": "_1o_qCCmVjukhPbvm6pwJWR",
	"heading": "AnIMOXBp1TTlEb3fvLyUa",
	"caption": "GQqibyygT2Ow7bJd8R7-V"
};