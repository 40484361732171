exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._39PhDND03Lsj0iCL7CoJE8 {\n    border: 1px outset #aaa;\n    font-size: 14px;\n    margin-bottom: 5px;\n    padding: 3em;\n    width: 95%;\n}\n", ""]);

// exports
exports.locals = {
	"paperSheet": "_39PhDND03Lsj0iCL7CoJE8"
};