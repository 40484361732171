// @flow strict
import Reflux from 'reflux';
import * as Immutable from 'immutable';

import { singletonActions } from 'views/logic/singleton';
import type { RefluxActions } from 'stores/StoreTypes';
import type { Pagination, PaginatedList } from 'stores/PaginationTypes';

import Team from '../../security/logic/Team';

export type PaginatedAuthTeams = PaginatedList<Team> & {
  context: {
    roles: Immutable.Map<string, { title: string }>,
    users: Immutable.Map<string, { title: string }>,
  },
};

export type TriggerTeamSyncResult = {
  success: boolean,
  message: string,
  errors: Array<string>,
  createdTeams: Array<string>,
  deletedTeams: Array<string>,
  updatedTeams: Array<string>,
};

export type ActionsType = {
  loadTeamsPaginated: (authBackendId: string, pagination: Pagination) => Promise<?PaginatedAuthTeams>,
  triggerTeamSync: (backendId: string, backendTitle: string) => Promise<TriggerTeamSyncResult>,
};

const AuthenticationActions: RefluxActions<ActionsType> = singletonActions(
  'enterprise.Authentication',
  () => Reflux.createActions({
    loadTeamsPaginated: { asyncResult: true },
    triggerTeamSync: { asyncResult: true },
  }),
);

export default AuthenticationActions;
