import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/graylog/router';

import { Alert } from 'components/graylog';
import Routes from 'routing/Routes';
import { isPermitted } from 'util/PermissionsMixin';

import styles from './LicenseNotification.css';

class LicenseViolationNotification extends React.Component {
  static propTypes = {
    /** Duration string since a license expired. (e.g. "3 months") */
    currentUser: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className={`container-fluid ${styles.licenseNotification}`}>
        <Alert bsStyle="danger">
          <h4 style={{ marginBottom: 5 }}><strong>Graylog Enterprise License Violation</strong></h4>
          <p>
            At least one term of your Graylog Enterprise license has been violated.{' '}
            {isPermitted(this.props.currentUser.permissions, 'licenses:create') ? (
              <span>
                Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for
                more information or contact your Graylog account manager.
              </span>
            ) : (
              <span>Contact your Graylog account manager for more information.</span>
            )}
          </p>
        </Alert>
      </div>
    );
  }
}

export default LicenseViolationNotification;
