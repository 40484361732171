// @flow strict
import React, { useContext, useEffect } from 'react';

import CombinedProvider from 'injection/CombinedProvider';
import { Row, Col } from 'components/graylog';
import { DocumentTitle, PageHeader } from 'components/common';
import CustomizationContext from 'contexts/CustomizationContext';
import UserNotification from 'util/UserNotification';

import HeaderBadgeConfiguration from './HeaderBadgeConfiguration';

const { CustomizationsActions } = CombinedProvider.get('Customizations');

const CUSTOMIZATION = 'org.graylog.plugins.customization.HeaderBadge';
const updateConfiguration = (config) => CustomizationsActions.update(CUSTOMIZATION, config).then(() => {
  UserNotification.success('Successfully updated.');
});

const CustomizationPage = () => {
  const badgeConfig = useContext(CustomizationContext)[CUSTOMIZATION];

  useEffect(() => {
    CustomizationsActions.get(CUSTOMIZATION);
  }, []);

  return (
    <DocumentTitle title="Customization">
      <div>
        <PageHeader title="Customization">
          <span>
            Here you can customize your Graylog experience.
          </span>
        </PageHeader>

        <Row className="content">
          <Col md={6}>
            <h2>UI Customization</h2>
            <HeaderBadgeConfiguration config={badgeConfig} updateConfig={updateConfiguration} />
          </Col>
        </Row>
      </div>
    </DocumentTitle>
  );
};

export default CustomizationPage;
