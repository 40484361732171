import PropTypes from 'prop-types';
import React from 'react';

import { Col, Row } from 'components/graylog';

import style from './PaperSheet.css';

const MIN_PAPER_HEIGHT = 150;

class PaperSheet extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
  };

  static defaultProps = {
    children: [],
  };

  state = {
    minPaperHeight: MIN_PAPER_HEIGHT,
  };

  componentDidMount() {
    this._updatePaperHeight();
  }

  _updatePaperHeight = () => {
    const width = this._paperSheet.offsetWidth;
    const minHeight = 1.41 * width;
    this.setState({ minPaperHeight: minHeight });
  };

  render() {
    return (
      <div ref={(component) => { this._paperSheet = component; }}
           className={style.paperSheet}
           style={{ minHeight: this.state.minPaperHeight }}>
        <Row>
          <Col md={12}>
            {this.props.children}
          </Col>
        </Row>
      </div>
    );
  }
}

export default PaperSheet;
