import React from 'react';
import PropTypes from 'prop-types';

import style from './Heading.css';

class Heading extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    return (
      <h3 className={style.heading}>{this.props.title}</h3>
    );
  }
}

export default Heading;
