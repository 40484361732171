// @flow strict
import Reflux from 'reflux';
import * as Immutable from 'immutable';
import { startCase, isEqual, trim } from 'lodash';

import SearchExecutionState, { getParameterBindingValue } from 'views/logic/search/SearchExecutionState';
import { SearchActions } from 'views/stores/SearchStore';
import Parameter from 'views/logic/parameters/Parameter';
import ValueParameter from 'views/logic/parameters/ValueParameter';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type { ParameterBindings } from 'views/logic/search/SearchExecutionState';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import { ViewStore } from 'views/stores/ViewStore';
import { SearchExecutionStateActions, SearchExecutionStateStore } from 'views/stores/SearchExecutionStateStore';
import type { RefluxActions } from 'stores/StoreTypes';

export const newParameterState = (parameterName: string): Parameter => {
  return new ValueParameter(
    parameterName,
    startCase(parameterName),
    '',
    'any',
    '',
    false,
    ParameterBinding.empty(),
  );
};

const hasMissingSearchParameters = (parameters: Immutable.Map<string, Parameter>, executionState: SearchExecutionState): boolean => {
  return parameters.filter((param: Parameter) => {
    if (!param.needsBinding && !param.binding) {
      return false;
    }

    const paramBindingValue = param.binding ? param.binding.value : undefined;
    const bindingValue = getParameterBindingValue(executionState, param.name) || '';

    return !trim(paramBindingValue) && !trim(bindingValue);
  }).size > 0;
};

export { hasMissingSearchParameters };

export type SearchParameterActionsType = RefluxActions<{
  load: (Immutable.Set<Parameter>) => Promise<ParameterMap>,
  declare: (ParameterMap) => Promise<ParameterMap>,
  remove: (string) => Promise<ParameterMap>,
  update: (string, Parameter) => Promise<ParameterMap>,
}>;
export const SearchParameterActions: SearchParameterActionsType = Reflux.createActions({
  load: { asyncResult: true },
  declare: { asyncResult: true },
  remove: { asyncResult: true },
  update: { asyncResult: true },
});

export const SearchParameterStore = Reflux.createStore({
  listenables: [SearchParameterActions],

  init() {
    this.listenTo(ViewStore, this.onViewStoreChange, this.onViewStoreChange);
    this.listenTo(SearchExecutionStateStore, this.onSearchExecutionStateStoreChange, this.onSearchExecutionStateStoreChange);
    this.parameters = Immutable.Map();
  },

  getInitialState(): Immutable.Map<string, Parameter> {
    return this._state();
  },

  onViewStoreChange({ view }) {
    if (view) {
      const { search } = view;
      const searchParameters = search.parameters || Immutable.Set();
      this.load(searchParameters);
    }
  },

  onSearchExecutionStateStoreChange(executionState: SearchExecutionState) {
    this.executionState = executionState;
  },

  load(searchParameters: Immutable.Set<Parameter>) {
    const newParameters = Immutable.Map(searchParameters.map((p: Parameter) => [p.name, p]));

    if (!isEqual(this.parameters, newParameters)) {
      this.parameters = newParameters;
      this._trigger();
    }

    SearchParameterActions.load.promise(Promise.resolve(this.parameters));
  },

  _newDefaultValues(newParameters: ParameterMap) {
    return Immutable.Map(
      newParameters.keySeq()
        .map((name) => newParameters.get(name))
        .filter((p: Parameter) => p.defaultValue)
        .map((p) => [p.name, p.defaultValue]),
    );
  },

  declare(newParameters: ParameterBindings) {
    if (!newParameters.isEmpty()) {
      this.parameters = this.parameters.merge(newParameters);

      const newDefaultValues = this._newDefaultValues(newParameters);

      const promise = this._propagateUpstream(this.parameters)
        .then(() => SearchExecutionStateActions.setParameterValues(newDefaultValues))
        .then(() => this.parameters);

      this._trigger();

      SearchParameterActions.declare.promise(promise);
    } else {
      SearchParameterActions.declare.promise(Promise.resolve(this.parameters));
    }
  },

  remove(parameterName: string) {
    const newParameters = this.parameters.delete(parameterName);
    let promise;

    if (!isEqual(this.parameters, newParameters)) {
      this.parameters = newParameters;
      promise = this._propagateUpstream(newParameters);
      promise.then(() => this._removeParameterBindingForRemovedParameter(parameterName));
      this._trigger();
    } else {
      promise = Promise.resolve(this.parameters);
    }

    SearchParameterActions.remove.promise(promise);
  },

  update(parameterName: string, newParameter: Parameter) {
    const newParameters = this.parameters.set(parameterName, newParameter);
    let promise;

    if (!isEqual(this.parameters, newParameters)) {
      this.parameters = newParameters;
      promise = this._propagateUpstream(newParameters);
      promise.then(() => this._updateParameterBindingForUpdatedParameter(parameterName, newParameter));
      this._trigger();
    } else {
      promise = Promise.resolve(this.parameters);
    }

    SearchParameterActions.update.promise(promise);
  },

  _removeParameterBindingForRemovedParameter(parameterName: string) {
    const { parameterBindings } = this.executionState;

    if (parameterBindings.has(parameterName)) {
      const newParameterBindings = parameterBindings.delete(parameterName);
      this.executionState = this.executionState.toBuilder().parameterBindings(newParameterBindings).build();

      return SearchExecutionStateActions.replace(this.executionState);
    }

    return Promise.resolve(this.executionState);
  },

  _updateParameterBindingForUpdatedParameter(parameterName: string, newParameter: Parameter) {
    const { parameterBindings } = this.executionState;

    if (!trim(parameterBindings.get(parameterName, ParameterBinding.empty()).value) && newParameter.defaultValue) {
      const newParameterBindings = parameterBindings.set(parameterName, ParameterBinding.forValue(newParameter.defaultValue));
      this.executionState = this.executionState.toBuilder().parameterBindings(newParameterBindings).build();

      return SearchExecutionStateActions.replace(this.executionState);
    }

    return Promise.resolve(this.executionState);
  },

  _propagateUpstream(newParameters: ParameterMap) {
    return SearchActions.parameters(newParameters.valueSeq().toList());
  },
  _state(): ParameterMap {
    return this.parameters;
  },
  _trigger() {
    this.trigger(this._state());
  },
});
